import { ActivityMonitorContainerProps } from "./types";
import { Card } from "react-bootstrap";
import CircularTabs from "app/storybookComponents/Tabs/CircularTabs";
import ActivityMonitorBox from "./ActivityMonitorBox";

const ActivityMonitorContainer = ({
  title,
  labelTags,
  activeTag,
  onTagClick,
  footer = null,
  boxProps,
  timeInterval = "week",
  titleTag = null,
  emptyStateContent = null,
  isEmpty = false,
}: Readonly<ActivityMonitorContainerProps>) => {
  const getBoxes = () => {
    if (!boxProps) return null;
    return (
      <div className="activity-monitor-boxes">
        {boxProps?.map((boxProp) => (
          <ActivityMonitorBox
            key={boxProp.title}
            timeInterval={timeInterval}
            {...boxProp}
          />
        ))}
      </div>
    );
  };

  const getContent = () => {
    if (isEmpty) {
      return emptyStateContent;
    }

    return (
      <>
        <CircularTabs
          tabs={labelTags.map((tag) => ({ label: tag }))}
          selectedTab={activeTag ?? undefined}
          onTabSelected={onTagClick}
        />
        {getBoxes()}
        {footer}
      </>
    );
  };

  return (
    <Card className="activity-monitor-container">
      <div className="activity-monitor-container__title">
        <h2>{title}</h2>
        {titleTag}
      </div>
      {getContent()}
    </Card>
  );
};

export default ActivityMonitorContainer;

import Button from "app/storybookComponents/Button";
import Toggle from "app/components/Toggle";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectAiFeaturesEnabled,
  selectCompanySettings,
  selectDepartments,
  updateDepartment,
} from "app/containers/AdminConsole/slice";
import { openEditDepartmentModal } from "app/components/Modals/slice";
import { useDebounceCallback } from "utils/hooks";
import { selectConfigCatFlag } from "../Global/slice";

interface Props {
  departmentId: number;
}

const DepartmentSettings = ({ departmentId }: Readonly<Props>) => {
  const dispatch = useAppDispatch();
  const departments = useAppSelector(selectDepartments);
  const areAIFeaturesEnabled = useAppSelector(selectAiFeaturesEnabled);
  const companySettings = useAppSelector(selectCompanySettings);
  const weeklyCheckInConfigCatFlag = useAppSelector(
    selectConfigCatFlag("weeklyCheckIn")
  );
  const departmentInfo = departments[departmentId];
  const getDepartmentNameSetting = () => (
    <div className="setting-row">
      <div className="setting-row-description">
        <h3>Department</h3>
        <p>{departmentInfo?.name}</p>
      </div>
      <div className="setting-row-action">
        <Button
          variant="secondary-blue"
          onClick={() => dispatch(openEditDepartmentModal({ departmentId }))}
        >
          Edit department name
        </Button>
      </div>
    </div>
  );

  const onCoachBoCheckInToggle = useDebounceCallback(
    (departmentHasCoachBoCheckInsEnabled?: boolean) => {
      dispatch(
        updateDepartment({
          hasCoachBoWeeklyCheckInsEnabled: departmentHasCoachBoCheckInsEnabled
            ? 0
            : 1,
          departmentId,
        })
      );
    },
    1000
  );

  const getWeeklyCheckInSetting = () => {
    if (!areAIFeaturesEnabled || !weeklyCheckInConfigCatFlag) return null;
    const isCompanySettingsEnabled =
      !!companySettings?.hasCoachBoWeeklyCheckInsEnabled;
    const departmentHasCoachBoCheckInsEnabled =
      departmentInfo?.hasCoachBoWeeklyCheckInsEnabled ??
      companySettings?.hasCoachBoWeeklyCheckInsEnabled; // first check if team has its own setting, if not, use company setting

    return (
      <>
        <hr className="m-0" />
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Enable Weekly Check-Ins</h3>
            <p>
              All team members in this department will receive an email from
              Coach Bo to check on work progress, share how they’re feeling, and
              report any successes or challenges at the end of each week.
              Leaders and managers will receive an email with key patterns,
              recommended actions, and a completion analysis based on weekly
              check-ins completed by members on their team(s) or in their
              reporting structure.
            </p>
          </div>
          <div className="setting-row-action">
            <Toggle
              isOn={!!departmentHasCoachBoCheckInsEnabled}
              handleToggle={() => {
                onCoachBoCheckInToggle(!!departmentHasCoachBoCheckInsEnabled);
              }}
            />
          </div>
        </div>
        {isCompanySettingsEnabled ? (
          <div className="light-blue warning-banner">
            <p style={{ color: "black" }}>
              Weekly Check-Ins have been <b>enabled</b> for everyone in this
              organization. If you choose to disable this feature, everyone in
              this department will not receive the Weekly Check-In email from
              Coach Bo. Note: People in this department may still receive the
              Weekly Check-In if this feature is enabled by any of their teams.
            </p>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className="column-gap-20px">
      {getDepartmentNameSetting()}
      {getWeeklyCheckInSetting()}
    </div>
  );
};

export default DepartmentSettings;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { request } from "utils/request";
import {
  DepartmentCardInfo,
  DepartmentEvent,
  DepartmentTrackingEvent,
  PendingTeamLeader,
  AllDepartmentAssessmentInfo,
  DepartmentResultsInfo,
  DepartmentResultsInfoByDepartmentId,
  PendingDepartmentLeader,
  CoachBoWeeklyCheckInActivityReports,
} from "./types";
import { toast } from "react-toastify";
import { successNotificationOptions } from "utils/constants";

// ------------------ State Type/Structure ------------------
export interface AdminConsoleState {
  departmentCardInfoById: {
    [departmentId: number]: DepartmentCardInfo;
  };
  departmentTrackingEvents: {
    [departmentId: number]: DepartmentTrackingEvent[];
  };
  getDepartmentTrackingEventsStatus: responseStatus;
  teamHasOpenAssessmentById: {
    [teamId: number]: boolean;
  };
  departmentTeamLeaders: {
    [departmentId: number]: PendingTeamLeader[];
  };
  showWeeklyCheckInActivityEmptyState: boolean;
  getWeeklyCheckInModuleStatus: responseStatus;
  allPendingTeamLeaders: PendingTeamLeader[];
  allPendingDepartmentLeaders: PendingDepartmentLeader[];
  coachBoWeeklyCheckInActivityReports: CoachBoWeeklyCheckInActivityReports;
  departmentAssessmentInfo: AllDepartmentAssessmentInfo;
  departmentResultsInfoByDepartmentId: DepartmentResultsInfoByDepartmentId;
  getCoachBoWeeklyCheckInActivityReportStatus: responseStatus;
  getAdminTeamsTeam360AnalyticsTextStatus: responseStatus;
  getDepartmentAssessmentInfoStatus: responseStatus;
  getDepartmentPendingTeamLeadersStatus: responseStatus;
  getDepartmentCardInfoStatus: responseStatus;
  getTeamHasOpenAssessmentStatus: responseStatus;
  getDepartmentResultsInfoStatus: responseStatus;
  remindTeamLeadersStatus: responseStatus;
  allPendingLeadersHaveBeenReminded: boolean;
  checkIfAllPendingLeadersHaveBeenRemindedStatus: responseStatus;
  gettingAllPendingTeamLeadersStatus: responseStatus;
  gettingAllPendingDepartmentLeadersStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: AdminConsoleState = {
  departmentTrackingEvents: {},
  departmentCardInfoById: {},
  departmentTeamLeaders: {},
  teamHasOpenAssessmentById: {},
  departmentAssessmentInfo: {},
  departmentResultsInfoByDepartmentId: {},
  allPendingLeadersHaveBeenReminded: false,
  allPendingTeamLeaders: [],
  allPendingDepartmentLeaders: [],
  coachBoWeeklyCheckInActivityReports: {},
  showWeeklyCheckInActivityEmptyState: false,
  getWeeklyCheckInModuleStatus: "idle",
  getCoachBoWeeklyCheckInActivityReportStatus: "idle",
  getAdminTeamsTeam360AnalyticsTextStatus: "idle",
  getDepartmentAssessmentInfoStatus: "idle",
  getDepartmentPendingTeamLeadersStatus: "idle",
  getDepartmentTrackingEventsStatus: "idle",
  getDepartmentCardInfoStatus: "idle",
  getTeamHasOpenAssessmentStatus: "idle",
  getDepartmentResultsInfoStatus: "idle",
  remindTeamLeadersStatus: "idle",
  checkIfAllPendingLeadersHaveBeenRemindedStatus: "idle",
  gettingAllPendingTeamLeadersStatus: "idle",
  gettingAllPendingDepartmentLeadersStatus: "idle",
};

// ------------------------------------ GETS ------------------------------------

export const getDepartmentTrackingEvents = createAsyncThunk(
  "dashboard/getDepartmentTrackingEvents",
  async (departmentId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departments/${departmentId}/events`;
    const response = (await request(requestUrl)) as DepartmentTrackingEvent[];
    return response;
  }
);

export const getDepartmentCardInfo = createAsyncThunk(
  "dashboard/getDepartmentCardInfo",
  async (departmentId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departments/${departmentId}/cardInfo`;
    const response = (await request(requestUrl)) as DepartmentCardInfo;
    return {
      response,
      departmentId,
    };
  }
);

export const getDepartmentPendingTeamLeaders = createAsyncThunk(
  "dashboard/getDepartmentPendingTeamLeaders",
  async (departmentId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departments/${departmentId}/pendingTeamLeaders`;
    const response = (await request(requestUrl)) as PendingTeamLeader[];
    return { response, departmentId };
  }
);

export const getDepartmentAssessmentCompletionInfo = createAsyncThunk(
  "dashboard/getAssessmentCompletionInfo",
  async () => {
    // TODO: Might change this endpoint to return the assessment information for a specific department instead of all departments
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/getAssessmentCompletionInfo`;
    const response = (await request(requestUrl)) as {
      departments: AllDepartmentAssessmentInfo;
    };
    return response?.departments;
  }
);

export const getDepartmentResultsInfo = createAsyncThunk(
  "dashboard/getDepartmentResultsInfo",
  async (departmentId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departments/${departmentId}/TEAM360ResultsInfo`;
    const response = (await request(requestUrl)) as DepartmentResultsInfo;
    return { response, departmentId };
  }
);

export const remindTeamLeaders = createAsyncThunk(
  "dashboard/remindTeamLeaders",
  async ({ departmentId }: { departmentId: number }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departments/${departmentId}/sendReminderToTeamLeads`;
    const response = await request(requestUrl, {
      method: "GET",
    });
    return response;
  }
);

export const checkIfAllPendingLeadersHaveBeenReminded = createAsyncThunk(
  "dashboard/checkIfAllPendingLeadersHaveBeenReminded",
  async ({ departmentId }: { departmentId: number }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departments/${departmentId}/allPendingLeadersHaveBeenReminded`;
    const response = (await request(requestUrl, {
      method: "GET",
    })) as { allPendingLeadersHaveBeenReminded: boolean };
    return response;
  }
);

export const getTeamHasOpenAssessment = createAsyncThunk(
  "teamGuide/getTeamHasOpenAssessment",
  async (teamId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/openAssessment`;
    const getTeamHasOpenAssessmentResponse = (await request(requestUrl)) as {
      teamHasOpenAssessment: boolean;
    };

    return { response: getTeamHasOpenAssessmentResponse, teamId };
  }
);

export const getPendingAllPendingTeamLeaders = createAsyncThunk(
  "dashboard/getPendingAllPendingTeamLeaders",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/pendingTeamLeaders`;
    const response = (await request(requestUrl)) as PendingTeamLeader[];
    return response;
  },
  {
    condition: (_, { getState }) => {
      const {
        dashboard: { gettingAllPendingTeamLeadersStatus },
      } = getState() as RootState;
      if (gettingAllPendingTeamLeadersStatus !== "idle") {
        return false;
      }
    },
  }
);

export const getPendingDepartmentLeaders = createAsyncThunk(
  "dashboard/getPendingDepartmentLeaders",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/pendingDepartmentLeaders`;
    const response = (await request(requestUrl)) as PendingDepartmentLeader[];
    return response;
  },
  {
    condition: (_, { getState }) => {
      const {
        dashboard: { gettingAllPendingDepartmentLeadersStatus },
      } = getState() as RootState;
      if (gettingAllPendingDepartmentLeadersStatus !== "idle") {
        return false;
      }
    },
  }
);

export const getCoachBoWeeklyCheckInActivityReport = createAsyncThunk(
  "dashboard/getCoachBoWeeklyCheckInActivityReport",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/checkInActivity`;
    const response = (await request(
      requestUrl
    )) as CoachBoWeeklyCheckInActivityReports;
    return response;
  },
  {
    condition: (_, { getState }) => {
      const {
        dashboard: { getCoachBoWeeklyCheckInActivityReportStatus },
      } = getState() as RootState;
      if (getCoachBoWeeklyCheckInActivityReportStatus !== "idle") {
        return false;
      }
    },
  }
);

// This endpoint will determine if we show the empty state for the check-in activity report
export const getWeeklyCheckInModuleStatus = createAsyncThunk(
  "dashboard/getWeeklyCheckInModuleStatus",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/weeklyCheckInStatus`;
    const response = (await request(requestUrl)) as {
      atLeastOneTeamHasCheckInsEnabled: boolean;
    };
    return response?.atLeastOneTeamHasCheckInsEnabled;
  },
  {
    condition: (_, { getState }) => {
      const {
        dashboard: { getWeeklyCheckInModuleStatus },
      } = getState() as RootState;
      if (getWeeklyCheckInModuleStatus !== "idle") {
        return false;
      }
    },
  }
);

// ------------------------------------ PUTS ------------------------------------

// ------------------------------------ POSTS ------------------------------------

export const trackDepartmentEvent = createAsyncThunk(
  "dashboard/trackDepartmentEvent",
  async ({
    departmentId,
    event,
  }: {
    departmentId: number;
    event: DepartmentEvent;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departments/${departmentId}/trackEvent`;
    const response = await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ event }),
    });
    return response;
  }
);

export const sendReminder = createAsyncThunk(
  "dashboard/sendReminder",
  async (payload: {
    reminderType:
      | "remindTeamLeader"
      | "remindTeamInvitation"
      | "remindUserToTakeAssessment"
      | "invitationReminder";
    userAccountId?: number;
    teamId?: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/sendReminder`;
    const response = await request(requestUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    });
    return response;
  }
);

// ------------------------------------ DELETES ------------------------------------

// ------------------ Beginning of Slice ------------------
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    addInvitedLeader: (
      state,
      { payload }: PayloadAction<PendingTeamLeader>
    ) => {
      return {
        ...state,
        departmentTeamLeaders: {
          ...state.departmentTeamLeaders,
          [payload.departmentId]: [
            ...(state.departmentTeamLeaders[payload.departmentId] || []),
            payload,
          ],
        },
      };
    },
    remindTeamLeaderForDepartment: (
      state,
      {
        payload,
      }: PayloadAction<{
        userAccountId: number;
        departmentId: number;
      }>
    ) => {
      const previousDepartmentTeamLeaders =
        state.departmentTeamLeaders[payload.departmentId];

      const updatedDepartmentTeamLeaders =
        previousDepartmentTeamLeaders?.map((teamLeader) =>
          teamLeader.userAccountId === payload.userAccountId
            ? { ...teamLeader, isReminded: true }
            : teamLeader
        ) ?? [];
      const updatedAllPendingTeamLeaders = state.allPendingTeamLeaders.map(
        (teamLeader) =>
          teamLeader.userAccountId === payload.userAccountId &&
          teamLeader.ti_onboardingDepartmentId === payload.departmentId
            ? { ...teamLeader, isReminded: true }
            : teamLeader
      );

      return {
        ...state,
        departmentTeamLeaders: {
          ...state.departmentTeamLeaders,
          [payload.departmentId]: updatedDepartmentTeamLeaders,
        },
        allPendingTeamLeaders: updatedAllPendingTeamLeaders,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentCardInfo.pending, (state) => {
        state.getDepartmentCardInfoStatus = "loading";
      })
      .addCase(
        getDepartmentCardInfo.fulfilled,
        (
          state,
          {
            payload: { response, departmentId },
          }: PayloadAction<{
            response: DepartmentCardInfo;
            departmentId: number;
          }>
        ) => {
          return {
            ...state,
            getDepartmentCardInfoStatus: "succeeded",
            departmentCardInfoById: {
              ...state.departmentCardInfoById,
              [departmentId]: {
                ...response,
                departmentId,
              },
            },
          };
        }
      )
      .addCase(getDepartmentCardInfo.rejected, (state) => {
        state.getDepartmentCardInfoStatus = "failed";
      })
      .addCase(getDepartmentTrackingEvents.rejected, (state) => {
        state.getDepartmentTrackingEventsStatus = "failed";
      })
      .addCase(getDepartmentTrackingEvents.pending, (state) => {
        state.getDepartmentTrackingEventsStatus = "loading";
      })
      .addCase(
        getDepartmentTrackingEvents.fulfilled,
        (state, { payload }: PayloadAction<DepartmentTrackingEvent[]>) => {
          const departmentTrackingEvents: {
            [departmentId: number]: DepartmentTrackingEvent[];
          } = {};
          payload.forEach((departmentTrackingEvent) => {
            const { departmentId } = departmentTrackingEvent;
            departmentTrackingEvents[departmentId] =
              departmentTrackingEvents[departmentId] || [];
            departmentTrackingEvents[departmentId].push(
              departmentTrackingEvent
            );
          });

          return {
            ...state,
            getDepartmentTrackingEventsStatus: "succeeded",
            departmentTrackingEvents: {
              ...state.departmentTrackingEvents,
              ...departmentTrackingEvents,
            },
          };
        }
      )
      .addCase(getTeamHasOpenAssessment.pending, (state) => {
        state.getTeamHasOpenAssessmentStatus = "loading";
      })
      .addCase(
        getTeamHasOpenAssessment.fulfilled,
        (
          state,
          {
            payload: { response, teamId },
          }: PayloadAction<{
            response: { teamHasOpenAssessment: boolean };
            teamId: number;
          }>
        ) => {
          return {
            ...state,
            getTeamHasOpenAssessmentStatus: "succeeded",
            teamHasOpenAssessmentById: {
              ...state.teamHasOpenAssessmentById,
              [teamId]: response.teamHasOpenAssessment,
            },
          };
        }
      )
      .addCase(getTeamHasOpenAssessment.rejected, (state) => {
        state.getTeamHasOpenAssessmentStatus = "failed";
      })
      .addCase(getDepartmentPendingTeamLeaders.pending, (state) => {
        state.getDepartmentPendingTeamLeadersStatus = "loading";
      })
      .addCase(
        getDepartmentPendingTeamLeaders.fulfilled,
        (
          state,
          {
            payload: { response, departmentId },
          }: PayloadAction<{
            response: PendingTeamLeader[];
            departmentId: number;
          }>
        ) => {
          return {
            ...state,
            getDepartmentPendingTeamLeadersStatus: "succeeded",
            departmentTeamLeaders: {
              ...state.departmentTeamLeaders,
              [departmentId]: response,
            },
          };
        }
      )
      .addCase(getDepartmentPendingTeamLeaders.rejected, (state) => {
        state.getDepartmentPendingTeamLeadersStatus = "failed";
      })
      .addCase(getDepartmentAssessmentCompletionInfo.pending, (state) => {
        state.getDepartmentAssessmentInfoStatus = "loading";
      })
      .addCase(
        getDepartmentAssessmentCompletionInfo.fulfilled,
        (state, { payload }: PayloadAction<AllDepartmentAssessmentInfo>) => {
          return {
            ...state,
            getDepartmentAssessmentInfoStatus: "succeeded",
            departmentAssessmentInfo: payload,
          };
        }
      )
      .addCase(getDepartmentAssessmentCompletionInfo.rejected, (state) => {
        state.getDepartmentAssessmentInfoStatus = "failed";
      })
      .addCase(getDepartmentResultsInfo.pending, (state) => {
        state.getDepartmentResultsInfoStatus = "loading";
      })
      .addCase(getDepartmentResultsInfo.fulfilled, (state, { payload }) => {
        return {
          ...state,
          getDepartmentResultsInfoStatus: "succeeded",
          departmentResultsInfoByDepartmentId: {
            ...state.departmentResultsInfoByDepartmentId,
            [payload.departmentId]: payload.response,
          },
        };
      })
      .addCase(getDepartmentResultsInfo.rejected, (state) => {
        state.getDepartmentResultsInfoStatus = "failed";
      })
      .addCase(checkIfAllPendingLeadersHaveBeenReminded.pending, (state) => {
        state.checkIfAllPendingLeadersHaveBeenRemindedStatus = "loading";
      })
      .addCase(
        checkIfAllPendingLeadersHaveBeenReminded.fulfilled,
        (state, { payload }) => {
          state.checkIfAllPendingLeadersHaveBeenRemindedStatus = "succeeded";
          state.allPendingLeadersHaveBeenReminded =
            payload.allPendingLeadersHaveBeenReminded;
        }
      )
      .addCase(checkIfAllPendingLeadersHaveBeenReminded.rejected, (state) => {
        state.checkIfAllPendingLeadersHaveBeenRemindedStatus = "failed";
      })
      .addCase(remindTeamLeaders.pending, (state) => {
        state.remindTeamLeadersStatus = "loading";
      })
      .addCase(remindTeamLeaders.fulfilled, (state) => {
        state.remindTeamLeadersStatus = "succeeded";
        toast.success("Reminders sent", successNotificationOptions);
      })
      .addCase(remindTeamLeaders.rejected, (state) => {
        state.remindTeamLeadersStatus = "failed";
      })
      .addCase(sendReminder.fulfilled, () => {
        toast.success("Reminders sent", successNotificationOptions);
      })
      .addCase(getPendingAllPendingTeamLeaders.pending, (state) => {
        state.gettingAllPendingTeamLeadersStatus = "loading";
      })
      .addCase(
        getPendingAllPendingTeamLeaders.fulfilled,
        (state, { payload }: PayloadAction<PendingTeamLeader[]>) => {
          state.allPendingTeamLeaders = payload ?? state.allPendingTeamLeaders;
          state.gettingAllPendingTeamLeadersStatus = "succeeded";
        }
      )
      .addCase(getPendingAllPendingTeamLeaders.rejected, (state) => {
        state.gettingAllPendingTeamLeadersStatus = "failed";
      })
      .addCase(getPendingDepartmentLeaders.pending, (state) => {
        state.gettingAllPendingDepartmentLeadersStatus = "loading";
      })
      .addCase(
        getPendingDepartmentLeaders.fulfilled,
        (state, { payload }: PayloadAction<PendingDepartmentLeader[]>) => {
          state.allPendingDepartmentLeaders =
            payload ?? state.allPendingDepartmentLeaders;
          state.gettingAllPendingDepartmentLeadersStatus = "succeeded";
        }
      )
      .addCase(getPendingDepartmentLeaders.rejected, (state) => {
        state.gettingAllPendingDepartmentLeadersStatus = "failed";
      })
      .addCase(getCoachBoWeeklyCheckInActivityReport.pending, (state) => {
        state.getCoachBoWeeklyCheckInActivityReportStatus = "loading";
      })
      .addCase(
        getCoachBoWeeklyCheckInActivityReport.fulfilled,
        (state, { payload }) => {
          state.getCoachBoWeeklyCheckInActivityReportStatus = "succeeded";
          state.coachBoWeeklyCheckInActivityReports = payload;
        }
      )
      .addCase(getCoachBoWeeklyCheckInActivityReport.rejected, (state) => {
        state.getCoachBoWeeklyCheckInActivityReportStatus = "failed";
      })
      .addCase(getWeeklyCheckInModuleStatus.pending, (state) => {
        state.getWeeklyCheckInModuleStatus = "loading";
      })
      .addCase(getWeeklyCheckInModuleStatus.fulfilled, (state, { payload }) => {
        state.getWeeklyCheckInModuleStatus = "succeeded";
        state.showWeeklyCheckInActivityEmptyState = !payload;
      })
      .addCase(getWeeklyCheckInModuleStatus.rejected, (state) => {
        state.getWeeklyCheckInModuleStatus = "failed";
      });
  },
});

// ------------------ Selectors -----------------
export const selectDepartmentCardInfoById =
  (departmentId: number | null) => (state: RootState) =>
    departmentId ? state.dashboard.departmentCardInfoById[departmentId] : null;
export const selectGetDepartmentCardInfoStatus = (state: RootState) =>
  state.dashboard.getDepartmentCardInfoStatus;
export const selectGetDepartmentTrackingEventsStatus = (state: RootState) =>
  state.dashboard.getDepartmentTrackingEventsStatus;
export const selectDepartmentTrackingEvents = (state: RootState) =>
  state.dashboard.departmentTrackingEvents;
export const selectTeamHasOpenAssessmentById =
  (teamId: number | null) => (state: RootState) =>
    teamId ? state.dashboard.teamHasOpenAssessmentById[teamId] : null;
export const selectGetTeamHasOpenAssessmentStatus = (state: RootState) =>
  state.dashboard.getTeamHasOpenAssessmentStatus;
export const selectDepartmentTeamLeadersByDepartmentId =
  (departmentId?: number | null) => (state: RootState) =>
    departmentId ? state.dashboard.departmentTeamLeaders[departmentId] : null;
export const selectGetDepartmentAssessmentInfoStatus = (state: RootState) =>
  state.dashboard.getDepartmentAssessmentInfoStatus;
export const selectDepartmentAssessmentInfo = (state: RootState) =>
  state.dashboard.departmentAssessmentInfo;
export const selectGetDepartmentResultsInfoStatus = (state: RootState) =>
  state.dashboard.getDepartmentResultsInfoStatus;
export const selectDepartmentResultsInfoByDepartmentId =
  (departmentId: number | null) => (state: RootState) => {
    return departmentId
      ? state.dashboard.departmentResultsInfoByDepartmentId[departmentId]
      : null;
  };
export const selectRemindTeamLeadersStatus = (state: RootState) =>
  state.dashboard.remindTeamLeadersStatus;
export const selectAllPendingLeadersHaveBeenReminded = (state: RootState) =>
  state.dashboard.allPendingLeadersHaveBeenReminded;
export const selectCheckIfAllPendingLeadersHaveBeenRemindedStatus = (
  state: RootState
) => state.dashboard.checkIfAllPendingLeadersHaveBeenRemindedStatus;
export const selectGetAdminTeamsTeam360AnalyticsTextStatus = (
  state: RootState
) => state.dashboard.getAdminTeamsTeam360AnalyticsTextStatus;
export const selectGetDepartmentPendingTeamLeadersStatus = (state: RootState) =>
  state.dashboard.getDepartmentPendingTeamLeadersStatus;
export const selectAllPendingTeamLeaders = (state: RootState) =>
  state.dashboard.allPendingTeamLeaders;
export const selectGettingAllPendingTeamLeadersStatus = (state: RootState) =>
  state.dashboard.gettingAllPendingTeamLeadersStatus;
export const selectGettingAllPendingDepartmentLeadersStatus = (
  state: RootState
) => state.dashboard.gettingAllPendingDepartmentLeadersStatus;
export const selectAllPendingDepartmentLeaders = (state: RootState) =>
  state.dashboard.allPendingDepartmentLeaders;
export const selectGetCoachBoWeeklyCheckInActivityReportStatus = (
  state: RootState
) => state.dashboard.getCoachBoWeeklyCheckInActivityReportStatus;
export const selectCoachBoWeeklyCheckInActivityReports = (state: RootState) =>
  state.dashboard.coachBoWeeklyCheckInActivityReports;
export const selectShowWeeklyCheckInActivityEmptyState = (state: RootState) =>
  state.dashboard.showWeeklyCheckInActivityEmptyState;
export const selectGetWeeklyCheckInModuleStatus = (state: RootState) =>
  state.dashboard.getWeeklyCheckInModuleStatus;

export const { addInvitedLeader, remindTeamLeaderForDepartment } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;

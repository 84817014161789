import SimpleModal from "app/components/Modals/SimpleModal";
import {
  selectWeeklyCheckInTableData,
  selectCheckInResponseSummaries,
  getCheckInResponseSummary,
  selectGetCheckInResponseSummaryStatus,
} from "../slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { selectAllCompanyUsersById } from "app/containers/Global/slice";
import AvatarCircle from "app/components/AvatarCircle";
import { useEffect, useMemo } from "react";
import Loading from "app/storybookComponents/Loading";
import { getOutOfFiveColor } from "app/containers/Dashboard/helpers";
import {
  isWeeklyCheckInRowUser,
  UserWeeklyCheckInTableRowData,
} from "../types";
import { getWeeklyCheckInTableRowEntityId } from "../helpers";
import { trackModalOpened } from "utils/trackingFunctions";

interface CheckInResponseSummaryModalProps {
  onHide: () => void;
  show: boolean;
  threadId?: string;
}

const CheckInResponseSummaryModal = ({
  onHide,
  show,
  threadId,
}: Readonly<CheckInResponseSummaryModalProps>) => {
  const dispatch = useAppDispatch();
  const weeklyCheckInTableData = useAppSelector(selectWeeklyCheckInTableData);
  const getCheckInResponseSummaryStatus = useAppSelector(
    selectGetCheckInResponseSummaryStatus
  );
  const checkInResponseSummaries = useAppSelector(
    selectCheckInResponseSummaries
  );
  const usersById = useAppSelector(selectAllCompanyUsersById);

  const foundCheckInData = useMemo(
    () =>
      weeklyCheckInTableData.find(
        (checkIn) =>
          isWeeklyCheckInRowUser(checkIn) && checkIn?.threadId === threadId
      ),
    [weeklyCheckInTableData, threadId]
  );
  const foundSummary = threadId ? checkInResponseSummaries[threadId] : null;

  useEffect(() => {
    if (threadId) {
      dispatch(getCheckInResponseSummary(threadId));
    }
  }, [dispatch, threadId]);

  useEffect(() => {
    if (show) {
      trackModalOpened("Check-In Response Summary");
    }
  }, [show]);

  const getFormattedDate = (date?: string) => {
    if (!date) {
      return "";
    }

    const dateObj = new Date(date);
    return dateObj.toLocaleDateString();
  };

  const getUserInfo = () => {
    const entityId =
      foundCheckInData && getWeeklyCheckInTableRowEntityId(foundCheckInData);
    const foundUser = entityId ? usersById[entityId] : null;

    if (!foundUser) {
      return null;
    }
    const { userAccountId, firstName, lastName, jobTitle } = foundUser;
    const fullName = `${firstName} ${lastName}`.trim();
    return (
      <div className="row-gap-8px">
        <AvatarCircle userAccountId={userAccountId} />
        <div>
          <p className="fw-bold">{fullName}</p>
          <p className="small-grey-text">{jobTitle}</p>
        </div>
      </div>
    );
  };

  const getScoreSpan = (label: string, score: number | null) => {
    const color = getOutOfFiveColor(score ?? 0);
    return (
      <div className={`out-of-5-span-tag ${color}`}>
        <p>
          {label}: {score ?? "N/A"}
        </p>
      </div>
    );
  };
  const getAllScores = () => {
    if (!foundCheckInData) {
      return null;
    }
    return (
      <div className="row-gap-16px">
        {getScoreSpan("Mood score", foundCheckInData.moodScore)}
        {getScoreSpan("Progress score", foundCheckInData.progressScore)}
        {getScoreSpan("Readiness score", foundCheckInData.readinessScore)}
      </div>
    );
  };

  const getContent = () => {
    if (getCheckInResponseSummaryStatus === "loading") {
      return <Loading />;
    }
    const { completedDate } =
      (foundCheckInData as UserWeeklyCheckInTableRowData) ?? {};

    return (
      <>
        <hr className="m-0" />
        <p>Check-In completed by</p>
        {getUserInfo()}
        {getAllScores()}
        <div className="column-gap-4px">
          <p className="grey-text">Response Summary:</p>
          <p>{foundSummary?.responseSummary}</p>
        </div>
        <hr className="m-0" />
        <p className="grey-text">
          This Weekly Check-In was completed on{" "}
          {getFormattedDate(completedDate)}
        </p>
      </>
    );
  };

  return (
    <SimpleModal show={show} onHide={onHide} title="Check-In Response Summary">
      {getContent()}
    </SimpleModal>
  );
};

export default CheckInResponseSummaryModal;

import { CompanySettings } from "app/containers/AdminConsole/types";
import { AppDispatch, store } from "utils/redux/store";
import {
  getDirectReportChatThreadId,
  resetChatbotState,
  selectChatbotConversationEntities,
  setChatbotFullScreen,
  setChatbotMessages,
  setCoachBotPromptReducer,
  setIsCoachbotOpen,
} from "app/containers/Chatbot/slice";
import { getManagerGuideChatbotPrompt } from "../WorkplaceInsightsReport/helper-functions";
import { ChatNotification, NewDirectReportNotification } from "./types";
import { dismissNotification } from "./slice";

export const getTeamName = (
  teamId: number,
  teamsById: {
    [key: number]: { teamName: string };
  }
) => {
  if (!teamsById[teamId]) return "";
  const { teamName } = teamsById[teamId];
  const teamNameLower = teamName.toLowerCase();
  // if the name does not contain the word "team", add it to the end
  if (!teamNameLower.includes("team")) return `${teamName} Team`;
  return teamName;
};

export const getConnectEppNotificationText = (
  companySettings: CompanySettings | null
) => {
  if (!companySettings?.linkResultsAccess) {
    return "Complete the Employee Personality Profile (EPP) to view your workplace personality insights.";
  }
  return "Complete the Employee Personality Profile (EPP) or connect previous results if you’ve completed it before.";
};

export const onChatWithDirectReportNotificationClick = ({
  userAccountId,
  dispatch,
  name,
}: {
  userAccountId: number;
  dispatch: AppDispatch;
  name: string;
}) => {
  dispatch(resetChatbotState());
  const template = getManagerGuideChatbotPrompt(name);
  dispatch(
    setCoachBotPromptReducer({
      ...template,
      chatType: "chatWithDirectReport",
      chatTypeId: userAccountId,
    })
  );
  dispatch(getDirectReportChatThreadId(userAccountId));
  dispatch(setIsCoachbotOpen(true));
};

export const onDismissDirectReportNotifications = async (
  dispatch: AppDispatch,
  userAccountIds: number[],
  directReportNotifications?: NewDirectReportNotification[]
) => {
  if (!directReportNotifications) return;
  // create a promise array to dismiss all of the notifications
  const promiseArray: Promise<any>[] = [];

  // iterate through all of the notifications and add the dismiss promise to the array if the userAccountId is in the userAccountIds array
  directReportNotifications.forEach((notification) => {
    if (userAccountIds.includes(notification.subjectUserAccountId)) {
      promiseArray.push(
        dispatch(dismissNotification(notification.notificationId))
      );
    }
  });
  await Promise.all(promiseArray);
};

export const onCoachBoCheckInNotificationActionClick = async (
  notification: ChatNotification,
  dispatch: AppDispatch,
  navigate: (path: string) => void
) => {
  const storeState = store.getState();
  const savedChatbotConversations =
    selectChatbotConversationEntities(storeState);
  const selectedConversation = savedChatbotConversations[notification.threadId];

  // we will redirect the user to the User Guide and open the chatbot
  navigate("/UserGuide");
  dispatch(resetChatbotState());
  dispatch(setChatbotFullScreen(true));
  dispatch(getDirectReportChatThreadId(notification.threadId));
  dispatch(setChatbotMessages(selectedConversation?.messages ?? []));
  dispatch(setIsCoachbotOpen(true));
};

import { Dropdown } from "react-bootstrap";

interface Props {
  children: React.ReactNode;
}

const TableDropdownMenu = ({ children }: Readonly<Props>) => {
  return (
    <Dropdown.Menu
      renderOnMount
      popperConfig={{
        strategy: "fixed",
      }}
    >
      {children}
    </Dropdown.Menu>
  );
};

export default TableDropdownMenu;

import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getWeeklyCheckInTableData,
  selectDepartments,
  selectWeeklyCheckInTableData,
  selectGetWeeklyCheckInTableDataStatus,
} from "../slice";
import SortableTable from "app/components/SortableTable";
import {
  selectAllCompanyUsersById,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import CheckInResponseSummaryModal from "../Modals/CheckInResponseSummaryModal";
import { Dropdown, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getOutOfFiveColor } from "app/containers/Dashboard/helpers";
import { Link } from "react-router-dom";
import { getSelectProps } from "utils/helperFunctions";
import { isWeeklyCheckInRowUser, WeeklyCheckInTableRowData } from "../types";
import ExportCSVButton from "app/storybookComponents/Button/ExportCSVButton";
import {
  getDateForSorting,
  getWeeklyCheckInTableRowEntityId,
} from "../helpers";
import EmptyCard from "app/storybookComponents/Cards/EmptyCard";
import Loading from "app/storybookComponents/Loading";
import {
  WeeklyCheckInInitialTimeInterval,
  WeeklyCheckInInitialEntityType,
  WeeklyCheckInTimeIntervals,
  WeeklyCheckInEntityTypes,
} from "../constants";
import { trackCSVExport, trackFeatureViewed } from "utils/trackingFunctions";

const CheckInActivityTable = () => {
  const dispatch = useAppDispatch();
  const weeklyCheckInTableData = useAppSelector(selectWeeklyCheckInTableData);
  const getWeeklyCheckInTableDataStatus = useAppSelector(
    selectGetWeeklyCheckInTableDataStatus
  );
  const allTeamsById = useAppSelector(selectTeamsByTeamId);
  const departments = useAppSelector(selectDepartments);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const [checkInResponseModalState, setCheckInResponseModalState] = useState<{
    isOpen: boolean;
    threadId: string;
  }>({ isOpen: false, threadId: "" });
  const [displayedInput, setDisplayedInput] = useState<string>("");
  const [entitySelected, setEntitySelected] = useState(
    WeeklyCheckInInitialEntityType
  );
  const [timeInterval, setTimeInterval] = useState(
    WeeklyCheckInInitialTimeInterval
  );

  useEffect(() => {
    trackFeatureViewed("Weekly Check-In Activity Table");
  }, []);

  useEffect(() => {
    dispatch(
      getWeeklyCheckInTableData({
        entityType: entitySelected,
        timeInterval,
      })
    );
  }, [dispatch, timeInterval, entitySelected]);

  const getScoreCell = (score: number | null) => {
    const dotColor = getOutOfFiveColor(score ?? 0);
    const displayValue =
      typeof score === "number" ? (
        <div className="row-gap-8px align-items-center">
          {score.toFixed()}
          <div className={`status-dot ${dotColor}`} />
        </div>
      ) : (
        "-"
      );

    return {
      sortValue: score,
      displayValue,
    };
  };

  const getDropdownFromRecord = (
    record: Record<string, string>,
    selected: string,
    onSelect: (str: string | null) => void,
    isDisabled: boolean = false
  ) => (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle
        id="dropdown-basic"
        className="d-flex align-items-center justify-content-between"
        style={{ width: "200px" }}
        variant="light"
        disabled={isDisabled}
      >
        {record[selected]}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.entries(record).map(([key, value]) => (
          <Dropdown.Item eventKey={key} key={key}>
            {value}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  const getEntityDisplayValue = (
    title:
      | {
          label: string;
          path: string;
        }
      | string,
    description?: string
  ) => {
    const titleElm =
      typeof title === "string" ? (
        <p className="user-name-cell__name">{title}</p>
      ) : (
        <Link to={title.path}>
          <p className="user-name-cell__name">{title.label}</p>
        </Link>
      );
    return (
      <div>
        {titleElm}
        {description ? <p className="small-grey-text">{description}</p> : null}
      </div>
    );
  };

  const getUserCell = (userAccountId: number) => {
    const user = usersById[userAccountId];
    if (!user) {
      return "";
    }
    const { firstName = "", lastName = "", jobTitle = "No job title" } = user;
    const fullName = `${firstName ?? ""} ${lastName ?? ""}`.trim();
    return {
      sortValue: fullName,
      displayValue: getEntityDisplayValue(
        { label: fullName, path: `/UserGuide/${userAccountId}` },
        jobTitle
      ),
    };
  };

  const getDepartmentCell = (departmentId: number, invitedCount: number) => {
    const department = departments[departmentId] ?? {};
    const { name = "" } = department;
    const memberString = `${invitedCount} invited`;

    return {
      sortValue: name,
      displayValue: getEntityDisplayValue(
        {
          label: name,
          path: `/DepartmentGuide/${departmentId}`,
        },
        memberString
      ),
    };
  };

  const getTeamCell = (teamId: number, invitedCount: number) => {
    const team = allTeamsById[teamId];
    const name = team?.teamName ?? "";
    const memberString = `${invitedCount} invited`;
    return {
      sortValue: name,
      displayValue: getEntityDisplayValue(
        {
          label: name,
          path: `/TeamGuide/${teamId}`,
        },
        memberString
      ),
    };
  };

  const getCompletionRateCell = (
    invitedCount: number,
    completedCount: number
  ) => {
    const completionRate = (completedCount / invitedCount) * 100;
    const completionRateString = `${completionRate.toFixed()} % (${completedCount}/${invitedCount})`;
    const dotColor = getOutOfFiveColor(completionRate / 20);
    return {
      sortValue: completionRate,
      displayValue: (
        <div className="row-gap-8px align-items-center">
          <p style={{ minWidth: "79px" }}>{completionRateString}</p>
          <div className={`status-dot ${dotColor}`} />
        </div>
      ),
    };
  };
  const getDateCell = (startDate: string, endDate?: string) => {
    const startDateObj = new Date(startDate);
    const startDisplayValue = startDateObj.toLocaleDateString("en-US", {
      month: endDate ? "short" : "long",
      day: "numeric",
    });

    // Guard clause for single date
    if (!endDate) {
      const timeString = startDateObj.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
      });
      return {
        sortValue: startDateObj.getTime(),
        displayValue: (
          <div>
            <p>{startDisplayValue}</p>
            <p className="small-grey-text">{timeString}</p>
          </div>
        ),
      };
    }

    // If endDate is provided, format as date range
    const endDateObj = new Date(endDate);
    const endDateString = endDateObj.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    const endYearString = endDateObj.getFullYear();
    return {
      sortValue: startDateObj.getTime(),
      displayValue: (
        <p>
          {startDisplayValue} - {endDateString}, {endYearString}
        </p>
      ),
    };
  };

  const getDropdownCell = (threadId: string) => ({
    displayValue: (
      <Dropdown
        onSelect={(e) => {
          if (e === "1") {
            setCheckInResponseModalState({
              isOpen: true,
              threadId,
            });
          }
        }}
        style={{ marginLeft: "auto" }}
      >
        <Dropdown.Toggle
          variant="outline-primary"
          id="dropdown-basic"
          className="no-caret"
        >
          <FontAwesomeIcon icon="ellipsis" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey="1">View response summary</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
    sortValue: 0,
  });

  const getFilteredRows = useCallback((): WeeklyCheckInTableRowData[] => {
    // First we sort the rows by date and then by user name
    const rows = Object.values(weeklyCheckInTableData);

    // If there is no input, return all rows
    if (!displayedInput) {
      return rows;
    }

    if (entitySelected === "team") {
      return rows.filter((row) => {
        const team = allTeamsById[getWeeklyCheckInTableRowEntityId(row)];
        if (!team) {
          return false;
        }
        return team.teamName
          .toLowerCase()
          .includes(displayedInput.toLowerCase());
      });
    }

    if (entitySelected === "department") {
      return rows.filter((row) => {
        const department = departments[getWeeklyCheckInTableRowEntityId(row)];
        if (!department) {
          return false;
        }
        return department.name
          ?.toLowerCase()
          .includes(displayedInput.toLowerCase());
      });
    }

    // Otherwise, filter the rows by user name or email
    return rows.filter((row) => {
      const user = usersById[getWeeklyCheckInTableRowEntityId(row)];
      if (!user) {
        return false;
      }
      const { firstName = "", lastName = "", emailAddress } = user;
      const fullName = `${firstName} ${lastName}`.trim();
      return (
        fullName.toLowerCase().includes(displayedInput.toLowerCase()) ||
        emailAddress.toLowerCase().includes(displayedInput.toLowerCase())
      );
    });
  }, [
    weeklyCheckInTableData,
    usersById,
    displayedInput,
    entitySelected,
    departments,
    allTeamsById,
  ]);

  const getTableRow = (data: WeeklyCheckInTableRowData) => {
    const mainContent = {
      moodScore: getScoreCell(data.moodScore),
      progressScore: getScoreCell(data.progressScore),
      readinessScore: getScoreCell(data.readinessScore),
    };

    if (isWeeklyCheckInRowUser(data)) {
      return {
        ...mainContent,
        user: getUserCell(data.userAccountId),
        date: getDateCell(data.completedDate),
        dropdown: getDropdownCell(data.threadId),
      };
    }
    const invitedCount = data.invitedUserAccountIds.length;
    const groupContent = {
      ...mainContent,
      date: getDateCell(data.startDate, data.endDate),
      completionRate: getCompletionRateCell(
        invitedCount,
        data.completedUserAccountIds.length
      ),
    };

    if (entitySelected === "department") {
      return {
        ...groupContent,
        department: getDepartmentCell(data.entityId, invitedCount),
      };
    }

    return {
      ...groupContent,
      team: getTeamCell(data.entityId, invitedCount),
    };
  };

  const getTableRows = () => getFilteredRows().map(getTableRow);

  const getSearchPlaceholder = () => {
    switch (entitySelected) {
      case "user":
        return "Search for a user...";
      case "team":
        return "Search for a team...";
      case "department":
        return "Search for a department...";
    }
  };

  const getSearchInput = () => {
    const { selectStyles, components } = getSelectProps();

    return (
      <Form.Group>
        <Select
          placeholder={getSearchPlaceholder()}
          isClearable={true}
          isSearchable={true}
          components={components}
          inputValue={displayedInput}
          styles={selectStyles}
          menuIsOpen={false}
          onInputChange={(e, actionMeta) => {
            if (actionMeta.action === "input-change") {
              setDisplayedInput(e);
            }
          }}
        />
      </Form.Group>
    );
  };

  const getNameCSVCellValue = useCallback(
    (entityId: number, entitySelected: string) => {
      if (entitySelected === "department") {
        return departments[entityId]?.name ?? "";
      }

      if (entitySelected === "team") {
        return allTeamsById[entityId]?.teamName ?? "";
      }
      const user = usersById[entityId];
      const { firstName = "", lastName = "" } = user ?? {};
      return `${firstName} ${lastName}`.trim();
    },
    [usersById, departments, allTeamsById]
  );

  const getCSVExportRows = useCallback(
    () =>
      getFilteredRows().map((data) => {
        const entityId =
          "userAccountId" in data ? data.userAccountId : data.entityId;
        const baseRow = {
          [entitySelected]: getNameCSVCellValue(entityId, entitySelected),
          date: getDateForSorting(data).toDateString(),
          moodScore: data.moodScore ?? "N/A",
          progressScore: data.progressScore ?? "N/A",
          readinessScore: data.readinessScore ?? "N/A",
        };

        if (!("threadId" in data)) {
          baseRow["completionRate"] = (
            (data.completedUserAccountIds.length /
              data.invitedUserAccountIds.length) *
            100
          ).toFixed(2);
        }
        trackCSVExport("Check-In Activity");

        return baseRow;
      }),
    [getFilteredRows, getNameCSVCellValue, entitySelected]
  );

  const getHeaders = () => {
    const nameHeaderLabel =
      entitySelected.charAt(0).toUpperCase() + entitySelected.slice(1);
    const mainHeaders = [
      { label: nameHeaderLabel, key: entitySelected },
      { label: "Date", key: "date" },
      { label: "Mood score", key: "moodScore" },
      { label: "Progress score", key: "progressScore" },
      { label: "Readiness score", key: "readinessScore" },
    ];

    if (entitySelected === "user") {
      return [
        ...mainHeaders,
        { label: "", key: "dropdown", disableSort: true },
      ];
    }

    mainHeaders.splice(1, 0, {
      label: "Completion Rate",
      key: "completionRate",
    });

    return mainHeaders;
  };

  const getEmptyState = () => {
    if (getWeeklyCheckInTableDataStatus === "loading") {
      return <Loading />;
    }
    if (weeklyCheckInTableData.length === 0) {
      return (
        <EmptyCard
          title="No check-in activity found"
          bodyText="Once there is check-in activity, it will be shown here."
        />
      );
    }
    if (rows.length === 0) {
      return (
        <EmptyCard
          title={`No ${entitySelected} found matching "${displayedInput}"`}
          bodyText="Try searching for a different name."
        />
      );
    }
    return null;
  };

  const rows = getTableRows();
  const headers = getHeaders();
  const isDropdownDisabled = getWeeklyCheckInTableDataStatus === "loading";

  return (
    <>
      <CheckInResponseSummaryModal
        show={checkInResponseModalState.isOpen}
        onHide={() => {
          setCheckInResponseModalState({
            isOpen: false,
            threadId: "",
          });
        }}
        threadId={checkInResponseModalState.threadId}
      />
      <div className="column-gap-8px">
        <h2>Check-In Completion Activity</h2>
        <p>Showing activity in this entire organization</p>
      </div>
      {getSearchInput()}
      <div className="d-flex justify-content-between align-items-center">
        <div className="row-gap-12px">
          {getDropdownFromRecord(
            WeeklyCheckInTimeIntervals,
            timeInterval,
            (e) => {
              setTimeInterval(e ?? "allTime");
              setDisplayedInput("");
            },
            isDropdownDisabled
          )}
          {getDropdownFromRecord(
            WeeklyCheckInEntityTypes,
            entitySelected,
            (e) => {
              setEntitySelected(e ?? "user");
              setDisplayedInput("");
            },
            isDropdownDisabled
          )}
        </div>
        <div>
          <ExportCSVButton
            headers={headers.filter((header) => header.key !== "dropdown")}
            getRows={getCSVExportRows}
          />
        </div>
      </div>
      <SortableTable
        rows={rows}
        tableClassName="admin-console-table"
        columnHeaders={[...headers]}
      />
      {getEmptyState()}
    </>
  );
};

export default CheckInActivityTable;

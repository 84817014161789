import {
  getAssessmentInstances,
  getAssessmentSeries,
  selectGettingAssessmentInstancesStatus,
  selectGettingAssessmentSeriesStatus,
  selectTeamAssessmentsInstancesById,
  selectTeamAssessmentsSeriesById,
} from "app/containers/Assessment/slice";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Nav, Dropdown } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectGetUserStatus,
  selectIsCurrentUserAdmin,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import {
  getDaysRemaining,
  getScoreClassName,
  getTeamIdsFromSurvey,
  splitSurveys,
} from "app/containers/Assessment/helpers";
import {
  AssessmentInformation,
  AssessmentInstance,
  AssessmentSeries,
} from "app/containers/Assessment/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getStringDate } from "app/components/InstancePicker/helpers";
import { getTeam360Score } from "app/components/Team360Assessment/helpers";
import Loading from "app/storybookComponents/Loading";
import SortableTable from "app/components/SortableTable";
import { TableHeader } from "app/components/SortableTable/types";
import { updateTeam360DateRange as updateTeamsTeam360DateRange } from "app/containers/TeamGuide/slice";
import { updateTeam360DateRange as updateDepartmentsTeam360DateRange } from "app/containers/DepartmentInsightReport/slice";
import { capitalizeWord } from "utils/helperFunctions";
import {
  selectDepartments,
  selectGetTeamAndDepartmentLeadsStatus,
  selectTeamAndDepartmentLeadIdsForLoggedInUser,
} from "../slice";
import TableDropdownMenu from "app/components/Dropdowns/TableDropdownMenu";

type AssessmentInstanceOrSeries = AssessmentInstance | AssessmentSeries;

export default function SurveyBreakdownTable() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  // ------------------------- State -------------------------
  const [selectedSurveyId, setSelectedSurveyId] = useState<null | number>(null);
  const [tabSelected, setTabSelected] = useState<"Teams" | "Departments">(
    "Teams"
  );
  const [state, setState] = useState<string>("Multiple Teams");
  const [surveyBreakdown, setSurveyBreakdown] = useState<{
    Teams: AssessmentInstanceOrSeries[];
    Departments: AssessmentInstanceOrSeries[];
  }>({
    Teams: [],
    Departments: [],
  });
  const [seriesOrInstance, setSeriesOrInstance] = useState<
    "series" | "instance"
  >("instance");

  // ------------------------- Selectors -------------------------
  const departments = useAppSelector(selectDepartments);
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const getUserStatus = useAppSelector(selectGetUserStatus);
  const teamsAndDepartmentsUserIsLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const getTeamAndDepartmentLeadsStatus = useAppSelector(
    selectGetTeamAndDepartmentLeadsStatus
  );
  const teamInfoById = useAppSelector(selectTeamsByTeamId);
  const selectedSurveyInstance = useAppSelector((state) =>
    selectedSurveyId
      ? selectTeamAssessmentsInstancesById(state, selectedSurveyId)
      : null
  );
  const selectedSurveySeries = useAppSelector((state) =>
    selectedSurveyId
      ? selectTeamAssessmentsSeriesById(state, selectedSurveyId)
      : null
  );
  const getAssessmentInstancesStatus = useAppSelector(
    selectGettingAssessmentInstancesStatus
  );
  const getAssessmentSeriesStatus = useAppSelector(
    selectGettingAssessmentSeriesStatus
  );
  const selectedSurvey = useMemo(
    () => selectedSurveyInstance ?? selectedSurveySeries,
    [selectedSurveyInstance, selectedSurveySeries]
  );

  // ------------------------- Effects -------------------------
  useEffect(() => {
    dispatch(getAssessmentInstances());
    dispatch(getAssessmentSeries());
  }, [dispatch]);

  useLayoutEffect(() => {
    const surveyId = searchParams.get("assessmentId");
    if (surveyId) {
      setSelectedSurveyId(parseInt(surveyId));
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedSurveySeries) {
      return setSeriesOrInstance("series");
    }
    setSeriesOrInstance("instance");
  }, [selectedSurveyInstance, selectedSurveySeries]);

  useEffect(() => {
    // If no search input no need to filter
    if (!selectedSurvey) {
      return;
    }

    const surveyObject = splitSurveys({
      selectedSurvey,
      departments,
      teamInfoById,
    });
    setSurveyBreakdown({
      Teams: surveyObject.teams,
      Departments: surveyObject.departments,
    });
    setTabSelected(surveyObject.teams?.length ? "Teams" : "Departments");

    // If the state is either multiple departments or full organization then we show the tab to select between teams and departments
    // If anything else then we hide the tab and only show the teams
    if (surveyObject.org) {
      return setState("Full Organization");
    }

    if (surveyObject.departments.length > 1) {
      return setState("Multiple Departments");
    }

    if (surveyObject.departments.length === 1) {
      // we check if the department exist inside of department object , if it exist then we set the state to be the name of the department,
      // additionally we check if the department name already includes the word department and if it does not then we add it
      const nestedSurveyObject = surveyObject.departments[0];
      const firstDepartmentId = nestedSurveyObject.departmentIds[0];
      const departmentName = departments[firstDepartmentId]?.name ?? "";
      if (!departmentName) {
        return setState("Single Department");
      }

      if (!departmentName.toLowerCase().includes("department")) {
        return setState(departmentName + " Department");
      }

      return setState(departmentName);
    }

    setState("Multiple Teams");
  }, [teamInfoById, departments, selectedSurvey]);

  // ------------------------- Callbacks -------------------------

  const getInviteesCell = useCallback(
    (
      invitedTeamIds?: number[],
      invitedDepartments?: number[],
      isOrgWide?: boolean
    ) => {
      if (isOrgWide) return "Full Organization";

      // If departmentId list array is not empty than we know that the survey was scheduled for a department
      if (invitedDepartments?.length) {
        let departmentName = departments[invitedDepartments[0]]?.name ?? "";
        if (!departmentName.toLowerCase().includes("department")) {
          departmentName += " Department";
        }

        return {
          displayValue: (
            <Link
              to={`/DepartmentGuide/${invitedDepartments[0]}?tab=TEAMscan`}
              onClick={() => {
                // Along with navigating to the department guide we also need to call updateTeam360DateRange(department) so that the correct instance is selected
                dispatch(
                  updateDepartmentsTeam360DateRange({
                    departmentId: invitedDepartments[0],
                    startDate: selectedSurvey?.startDate,
                    endDate: selectedSurvey?.endDate,
                    instance: selectedSurvey?.startDate,
                  })
                );
              }}
            >
              {departmentName}
            </Link>
          ),
          sortValue: departmentName,
        };
      }

      if (invitedTeamIds?.length) {
        let teamName = teamInfoById[invitedTeamIds[0]]?.teamName ?? "";
        if (!teamName.toLowerCase().includes("team")) {
          teamName += " Team";
        }

        return {
          displayValue: (
            <Link
              to={"/TeamGuide/" + invitedTeamIds[0] + "?tab=TEAMscan"}
              onClick={() => {
                // Along with navigating to the team guide we also need to call updateTeam360DateRange(team) so that the correct instance is selected
                dispatch(
                  updateTeamsTeam360DateRange({
                    teamId: invitedTeamIds[0],
                    startDate: selectedSurvey?.startDate,
                    endDate: selectedSurvey?.endDate,
                    instance: selectedSurvey?.startDate,
                  })
                );
              }}
            >
              {teamName}
            </Link>
          ),
          sortValue: teamName,
        };
      }

      return "N/A";
    },
    [departments, teamInfoById, dispatch, selectedSurvey]
  );

  const onViewResults = useCallback(
    (teamAssessment?: AssessmentSeries | AssessmentInstance) => {
      if (!teamAssessment) return;
      const { departmentIds } = teamAssessment;
      const teamIds = getTeamIdsFromSurvey(teamAssessment);
      if (teamIds?.length === 1) {
        // Redirect to TEAMscan page for that team and additionally will need to call updateTeam360DateRange(team) so that the correct instance is selected
        dispatch(
          updateTeamsTeam360DateRange({
            teamId: teamIds[0],
            startDate: teamAssessment.startDate,
            endDate: teamAssessment.endDate,
            instance: teamAssessment.startDate,
          })
        );
        return navigate(`/TeamGuide/${teamIds[0]}?tab=TEAMscan`);
      }

      if (departmentIds?.length === 1) {
        // Redirect to department guide page and additionally will need to call updateTeam360DateRange(department) so that the correct instance is selected
        dispatch(
          updateDepartmentsTeam360DateRange({
            departmentId: departmentIds[0],
            startDate: teamAssessment.startDate,
            endDate: teamAssessment.endDate,
            instance: teamAssessment.startDate,
          })
        );

        return navigate(`/DepartmentGuide/${departmentIds[0]}?tab=TEAMscan`);
      }
    },
    [navigate, dispatch]
  );

  const onDepartmentTableDropdownSelect = useCallback(
    (
      e: string | null,
      selectedSurvey: AssessmentSeries | AssessmentInstance
    ) => {
      // const id = selectedSurvey.assessmentId;
      switch (e) {
        case "viewResults": {
          onViewResults(selectedSurvey);
          return;
        }
        case "remindMembers": {
          // Will open the remindWarningModal and set the state to the scheduledSurveyId
          // setShowRemindWarningModal(id);
          return;
        }
        // Will open the extendExpirationWarningModal and set the state to the scheduledSurveyId
        // Will open the editSurveySettingsModal and set the state to the scheduledSurveyId
        case "extendSurvey":
        case "editSurveySettings": {
          // dispatch(showScheduleAssessmentModalForSurveyEditId(id));
          // setExtendExpirationWarningModal(id);
          return;
        }
        case "deactivateSurvey":
          // Will open the deactivateWarningModal and set the state to the scheduledSurveyId
          // setShowDeactivateWarningModal(id);
          return;
      }
    },
    [onViewResults]
  );

  const getCompletionRateCell = useCallback((completionRate?: number) => {
    if (completionRate === undefined)
      return {
        displayValue: "N/A",
        sortValue: 0,
      };

    const getClassName = (completionRate: number) => {
      if (completionRate > 75) {
        return "green";
      } else if (completionRate > 50) {
        return "yellow";
      } else if (completionRate > 25) {
        return "poppy";
      } else {
        return "red";
      }
    };
    const className = `status-dot ${getClassName(completionRate || 1)}`;

    return {
      displayValue: (
        <div className="row-gap-8px align-items-center">
          <p>{completionRate}%</p>
          <div className={className} />
        </div>
      ),
      sortValue: completionRate,
    };
  }, []);

  const getTeamworkScore = useCallback((score?: AssessmentInformation) => {
    const { team360Scores } = score ?? {};
    if (score === undefined || !team360Scores) {
      return {
        displayValue: "N/A",
        sortValue: 0,
      };
    }

    let scoreNum = 0;
    // If company exist then just use the overall score for the company
    if (team360Scores?.company) {
      scoreNum = team360Scores.company?.overall ?? 0;

      // If no company score but has the teams score iterate through all the teams and get the average
    } else if (team360Scores?.teams) {
      let length = 0;
      Object.values(team360Scores.teams).forEach((team) => {
        scoreNum += team?.overall ?? 0;
        length++;
      });
      scoreNum = scoreNum / length;

      // If no teams or company but it does have departments then iterate through all the departments and get the average
    } else if (team360Scores?.departments) {
      const departmentObject = Object.values(team360Scores.departments);
      departmentObject.forEach((department) => {
        scoreNum += department?.overall ?? 0;
      });
      scoreNum = scoreNum / departmentObject.length;
    }
    scoreNum = scoreNum && getTeam360Score(scoreNum);
    const daysRemaining = getDaysRemaining(score.endDate);
    const emptyScoreString =
      daysRemaining && daysRemaining > 0 ? "Results Pending" : "No Results";
    return {
      displayValue: (
        <div
          className={`verbal-tag bigger ${getScoreClassName(scoreNum)}`}
          style={{ width: "fit-content" }}
        >
          {scoreNum || emptyScoreString}
        </div>
      ),
      sortValue: scoreNum,
    };
  }, []);

  const getRows = useCallback(
    (selectedSurveys: (AssessmentSeries | AssessmentInstance)[]) => {
      let filteredSelectedSurveys = selectedSurveys;

      const departmentId = searchParams.get("departmentId");
      // If a departmentId is passed in via url, we filter out any teams that do not belong to that department.
      if (departmentId) {
        filteredSelectedSurveys = selectedSurveys.filter((selectedSurvey) =>
          getTeamIdsFromSurvey(selectedSurvey).some(
            (teamId) =>
              teamInfoById[teamId]?.departmentId === Number(departmentId)
          )
        );
      }
      return filteredSelectedSurveys.map((selectedSurvey) => {
        const {
          startDate,
          endDate,
          status,
          completionRate,
          departmentIds,
          inviteOrg,
          totalCompleted,
        } = selectedSurvey;
        const teamIds = getTeamIdsFromSurvey(selectedSurvey);

        return {
          name: "TEAMscan",
          startDate: getStartAndEndDate(startDate) ?? "N/A",
          endDate: getStartAndEndDate(endDate) ?? "N/A",
          invitees: getInviteesCell(teamIds, departmentIds, !!inviteOrg),
          status: {
            displayValue: (
              <div className="row-gap-12px">
                <div
                  className={`label-tag bigger ${getStatusColor(status)} m-0`}
                >
                  {status}
                </div>
              </div>
            ),
            sortValue: getStatusSortValue(status),
          },
          completionRate: getCompletionRateCell(completionRate),
          numberOfResponses: totalCompleted,
          teamworkScore: getTeamworkScore(selectedSurvey),
          dropdown: {
            displayValue: (
              <Dropdown
                onSelect={(e) =>
                  onDepartmentTableDropdownSelect(e, selectedSurvey)
                }
                className="d-flex justify-content-end"
              >
                <Dropdown.Toggle
                  variant="outline-primary"
                  id="dropdown-basic"
                  className="no-caret"
                >
                  <FontAwesomeIcon icon="ellipsis" />
                </Dropdown.Toggle>
                {getActionDropdownOptions(selectedSurvey)}
              </Dropdown>
            ),
            sortValue: 0,
          },
        };
      });
    },
    [
      getInviteesCell,
      getCompletionRateCell,
      getTeamworkScore,
      onDepartmentTableDropdownSelect,
    ]
  );

  // ------------------------- Handlers -------------------------
  // ------------------------- Getters -------------------------

  const getActionDropdownOptions = (
    assessment: AssessmentSeries | AssessmentInstance
  ) => {
    const { status } = assessment;

    const key = status.toLowerCase();
    switch (key) {
      case "active": // Active
        return (
          <TableDropdownMenu>
            <Dropdown.Item eventKey="viewResults">View Results</Dropdown.Item>
            {/* <Dropdown.Divider />
            <Dropdown.Divider />
            <Dropdown.Item eventKey="extendSurvey">
              Extend Survey Period
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="deactivateSurvey" className="danger">
              Deactivate Survey
            </Dropdown.Item> */}
          </TableDropdownMenu>
        );
      case "upcoming": // Upcoming
      case "past": // Past
        return (
          <TableDropdownMenu>
            <Dropdown.Item eventKey="viewResults">View Results</Dropdown.Item>
          </TableDropdownMenu>
        );
      default:
        return null;
    }
  };

  const getStartAndEndDate = (date?: string) => {
    if (!date) {
      return null;
    }
    return getStringDate(new Date(date));
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Active":
        return "green";
      case "Upcoming":
        return "yellow";
      case "Past":
      default:
        return "grey";
    }
  };

  const getStatusSortValue = (status: string) => {
    switch (status) {
      case "Active":
        return 1;
      case "Upcoming":
        return 2;
      case "Past":
      default:
        return 3;
    }
  };

  const getNav = () => {
    if (state === "Full Organization" || state === "Multiple Departments") {
      const capitalSeriesOrInstance = capitalizeWord(seriesOrInstance);
      return (
        <Nav
          className="simple-nav"
          activeKey={tabSelected ?? ""}
          onSelect={(tab) => {
            if (tab === "Teams" || tab === "Departments") {
              setTabSelected(tab);
            }
          }}
          style={{
            gap: "24px",
          }}
        >
          <Nav.Item>
            <Nav.Link eventKey="Departments">
              Departments in this {capitalSeriesOrInstance}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Teams">
              Teams in this {capitalSeriesOrInstance}
            </Nav.Link>
          </Nav.Item>
        </Nav>
      );
    }
    return null;
  };

  const getSnapshotBox = () => {
    let includesText = "";
    if (surveyBreakdown.Departments.length) {
      includesText += `${surveyBreakdown.Departments.length} department${
        surveyBreakdown.Departments.length > 1 ? "s" : ""
      }`;
    }

    if (surveyBreakdown.Teams.length) {
      includesText += includesText ? " and " : "";
      includesText += `${surveyBreakdown.Teams.length} team${
        surveyBreakdown.Teams.length > 1 ? "s" : ""
      }`;
    }

    return (
      <div className="snapshot-box">
        <p>
          <b>Showing results for:</b> The instance launched for <b>{state}</b>
          {includesText && ", which includes "}
          {includesText}, from{" "}
          <b>
            {getStartAndEndDate(selectedSurvey?.startDate)} to{" "}
            {getStartAndEndDate(selectedSurvey?.endDate)}
          </b>{" "}
          .
        </p>
      </div>
    );
  };

  const getTitle = () => {
    if (state === "Full Organization" || state === "Multiple Departments") {
      return `${entityCapitalized} Breakdown`;
    }
    return `Teams In This ${seriesOrInstance}`;
  };

  const getEmptyCard = () => {
    if (
      getAssessmentSeriesStatus === "loading" ||
      getAssessmentInstancesStatus === "loading"
    ) {
      return <Loading />;
    }

    if (rows.length !== 0) {
      return null;
    }
    const tabLowercase = tabSelected.toLowerCase();
    const seriesOrInstanceLowercase = seriesOrInstance.toLowerCase();
    const emptyCardTitle = `No ${tabLowercase} in this ${seriesOrInstanceLowercase}`;
    const emptyCardDescription = `No ${tabLowercase} were invited to take TEAMscan in this ${seriesOrInstanceLowercase} launched.`;

    return (
      <div
        className="empty-card"
        style={{
          padding: "32px",
        }}
      >
        <div className="column-gap-12px">
          <p style={{ fontSize: "16px" }}>
            <b>{emptyCardTitle}</b>
          </p>
          <p>{emptyCardDescription}</p>
        </div>
      </div>
    );
  };

  const getTableStructure = (): TableHeader[] => {
    const tableHeaders: TableHeader[] = [
      {
        key: "invitees",
        label: "Invitee(s)",
      },
    ];

    if (
      selectedSurvey?.status === "Active" ||
      selectedSurvey?.status === "Past"
    ) {
      tableHeaders.push(
        ...[
          {
            key: "teamworkScore",
            label: "Teamwork Score",
            sortInverse: true,
          },
          {
            key: "completionRate",
            label: "Completion Rate",
            sortInverse: true,
          },
          {
            key: "numberOfResponses",
            label: "# of Responses",
            sortInverse: true,
          },
        ]
      );
    }
    return [
      ...tableHeaders,
      {
        key: "dropdown",
        label: "",
        className: "table-dropdown-header",
      },
    ];
  };

  const getTable = () => (
    <div
      className="admin-console-table-container"
      style={{
        marginTop: "-4px",
      }}
    >
      <SortableTable
        rows={rows}
        tableClassName="admin-console-table"
        columnHeaders={getTableStructure()}
      />
    </div>
  );

  // ------------------------- Render -------------------------
  // This will be the parent survey that should hold all the data for the children surveys
  const entityCapitalized = capitalizeWord(seriesOrInstance);
  const rows = useMemo(
    () => getRows(surveyBreakdown[tabSelected]),
    [getRows, surveyBreakdown, tabSelected]
  );

  const isDepartmentLeader = useMemo(() => {
    const selectedDepartmentId = searchParams.get("departmentId");
    return (
      selectedDepartmentId &&
      teamsAndDepartmentsUserIsLeaderOf?.departments?.includes(
        Number(selectedDepartmentId)
      )
    );
  }, [searchParams, teamsAndDepartmentsUserIsLeaderOf]);

  const getNoPermissionContent = () => {
    return <>You do not have permission to view this page.</>;
  };

  const getDepartmentInstanceContent = (departmentId: number) => {
    const department = departments[departmentId];
    let departmentName = department?.name ?? "";
    if (!departmentName.toLowerCase().includes("department")) {
      departmentName += " Department";
    }
    return (
      <div className="column-gap-24px">
        <h2>Teams in {departmentName}</h2>
        <div className="snapshot-box">
          <p>
            <b>Showing results for:</b> The instance was launched for{" "}
            <b>{state}</b>, which includes results from{" "}
            <b>
              {getStartAndEndDate(selectedSurvey?.startDate)} to{" "}
              {getStartAndEndDate(selectedSurvey?.endDate)}
            </b>{" "}
            .
          </p>
        </div>
        {getTable()}
        {getEmptyCard()}
      </div>
    );
  };

  const getContent = () => {
    const selectedDepartmentId = searchParams.get("departmentId");
    if (
      getUserStatus === "loading" ||
      (selectedDepartmentId && getTeamAndDepartmentLeadsStatus === "loading")
    ) {
      return <Loading />;
    }

    if (selectedDepartmentId && (isAdmin || isDepartmentLeader)) {
      return getDepartmentInstanceContent(Number(selectedDepartmentId));
    }

    if (isAdmin) {
      return (
        <div className="column-gap-24px">
          <h2>{getTitle()}</h2>
          {getSnapshotBox()}
          {getNav()}
          {getTable()}
          {getEmptyCard()}
        </div>
      );
    }

    return getNoPermissionContent();
  };

  return getContent();
}

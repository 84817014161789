import {
  Team360AssessmentResult,
  SurveyEventFrequency,
} from "app/components/Team360Assessment/types";
import { ADMIN_CONSOLE_TABS } from "./constants";
import { PermissionChartTemplate } from "app/storybookComponents/Charts/types";

export type AdminConsoleTab =
  | "Dashboard"
  | "Departments"
  | "Surveys"
  | "SurveyBreakdown"
  | "Teams"
  | "People"
  | "Settings"
  | "Billing"
  | "Analytics"
  | "Automations"
  | "Check-In Activity"
  | "Emails & Notifications";

export const isAdminConsoleTab = (value: any): value is AdminConsoleTab => {
  return ADMIN_CONSOLE_TABS.includes(value);
};

export type OneTwoThree = 1 | 2 | 3;
export type GenericAdminNumberOption = OneTwoThree | 4;
export type CompanySettings = {
  emailSetting: OneTwoThree; // 1 = all domains, 2 = only current domain, 3 = custom list
  invitationAccess: GenericAdminNumberOption; // 1 | 2 | 3 | 4 (All Members | Only Admins | Only Team Leaders | Custom)
  teamCreationAccess: GenericAdminNumberOption; // 1 | 2 | 3 | 4 (All Members | Only Admins | Only Team Leaders | Custom)
  companyAccountId: number;
  globalColorId: number;
  linkResultsAccess: 0 | 1;
  originalDomain: string;
  // Optional fields
  logo?: string;
  globalHeader?: string;
  customDomainList?: null | string[];
  invitationCustomList?: null | number[];
  teamCreationCustomList?: null | number[];
  globalHeaderBase64?: string | null;
  areDepartmentsHidden?: 0 | 1;
  aiFeaturesEnabled: 0 | 1;
  hasCoachBoConversationHistoryEnabled: 0 | 1;
  hasSuggestedConversations?: 0 | 1;
  // hasReflectionQuestions?: 0 | 1; // Commenting this out for now, to reduce scope of DQP-41
  visibilityPermissionDefaultId?: 1 | 2 | 3;
  allowVisibilityPermissionOverride?: 0 | 1;
  requireTeamAcceptance?: 0 | 1;
  showAutomationSettings?: 0 | 1;
  hasCoachBoWeeklyCheckInsEnabled?: 0 | 1;
};

export type CompanySettingPayload = Partial<
  Pick<
    CompanySettings,
    | "teamCreationAccess"
    | "linkResultsAccess"
    | "globalColorId"
    | "emailSetting"
    | "customDomainList"
    | "invitationAccess"
    | "areDepartmentsHidden"
    | "invitationCustomList"
    | "teamCreationCustomList"
    | "aiFeaturesEnabled"
    | "hasCoachBoConversationHistoryEnabled"
    | "hasSuggestedConversations"
    // | "hasReflectionQuestions" // Commenting this out for now, to reduce scope of DQP-41
    | "visibilityPermissionDefaultId"
    | "allowVisibilityPermissionOverride"
    | "requireTeamAcceptance"
  > & {
    globalHeader?: string; // The name of the file
    globalHeaderImage?: string; // The base64 string
    deleteGlobalHeader?: true;
  }
>;

export type CompanyVisibilitySettingPayload = {
  visibilityPermissionDefaultId?: 1 | 2 | 3;
  reconfigureVisibilityPermissions?: 1 | 0;
  allowVisibilityPermissionOverride?: 1 | 0;
};

export type CompanySettingDropdown =
  | "Who Can Join"
  | "Who Can Create"
  | "Who Can Invite";

export type Department = {
  name?: string;
  leader?:
    | {
        firstName: string;
        lastName: string;
        emailAddress: string;
        userAccountId: number;
      }
    | number
    | null;
  departmentId: number;
  departmentTypeId?: number | null;
  companyAccountId?: number;
  teamMembers?: number[]; // This is the userAccountIds
  teams?: number[]; // This is the teamIds
  departmentLeaderEmail?: string; // This is only returned when creating a new department
  hasCoachBoWeeklyCheckInsEnabled?: 0 | 1 | null;
};
export type DepartmentType = {
  departmentTypeId: number;
  name: string;
};

export type WarningModalState =
  | false
  | ({
      id: number;
      warningTitle: string;
      warningMessage: string;
    } & (
      | {
          modalTitle: "Change Permissions";
          isDanger: false;
          customButtonText: "Confirm Changes";
          tmgRoleId: 1 | 2;
        }
      | {
          modalTitle: "Delete Team";
          isDanger: true;
          customButtonText: "Confirm Delete";
        }
      | {
          modalTitle: "Delete Department";
          isDanger: true;
          customButtonText: "Confirm Delete";
        }
    ));

// TODO update the payload based on the following :
// Changes made: removed emailReminders, reminderFrequency, and reminderLimit keys. Added stopRecurringDate. Additionally, it looks like the FE payload for this endpoint is messed up when making a recurring assessment. Often the endDate is before the start date.
export type ScheduleSurveyPayload = {
  testId: number;
  inviteOrg: boolean;
  teamIds: number[];
  userAccountIds: number[];
  departmentIds: number[];
  frequency: Lowercase<SurveyEventFrequency> | "once";
  customMessage: string;
  startDate: string;
  endDate: string | null;
  sendInvitations: 0 | 1;
};

export type ScheduleSurveyPayload2 = {
  testId: number;
  inviteOrg: boolean;
  teamIds: number[];
  userAccountIds: number[];
  departmentIds: number[];
  frequency: Lowercase<SurveyEventFrequency> | "once";
  customMessage: string;
  stopRecurringDate: string | null;
  startDate: string;
  endDate: string | null;
};

export type ClassificationArray = {
  "high-performing": number;
  healthy: number;
  "needs-help": number;
};

export type AnalyticsText = {
  overview: OverviewAnalyticsText | null;
  teamSummary: TeamSummaryAnalyticsText | null;
  departmentSummary: DepartmentSummaryAnalyticsText | null;
  varianceReport: VarianceReportAnalyticsText | null;
  completionInfo: CompletionInfoAnalyticsText;
  teamReflectionSummary: TeamReflectionSummary | null;
  teamLeaderScores: Team360AssessmentResult | null;
  teamMemberScores: Team360AssessmentResult | null;
};

export interface CompletionInfoAnalyticsText {
  totalInvited: number;
  totalCompleted: number;
  completionRate: number;
  totalPeopleInvited?: number;
}

export interface VarianceReportAnalyticsText {
  bottomGroupAverage: number;
  middleGroupAverage: number;
  topGroupAverage: number;
  lessThan10Teams: boolean;
  averageManagerScore: number;
  averageTeamMemberScore: number;
  managersVsMembersText: string;
}

export interface DepartmentSummaryAnalyticsText {
  departmentSummaryText: string;
  classificationArray: ClassificationArray;
  highestScoringDepartment: {
    departmentId: number;
    overall: number;
    highestDimension: {
      dimension: string;
      score: number;
    };
    text: string;
  };
  lowestScoringDepartment: {
    departmentId: number;
    departmentName: string;
    overall: number;
    lowestDimension: {
      dimension: string;
      score: number;
    };
    text: string;
  };
  dimensionVarianceText: string;
}

export interface TeamSummaryAnalyticsText {
  teamCount: number;
  teamSummaryText: string;
  classificationArray: ClassificationArray;
  highestScoringDimensionText: string;
  lowestScoringDimensionText: string;
  highestScoringTeam: AnalyticsTextTeamScore;
  lowestScoringTeam: AnalyticsTextTeamScore;
  percentiles?: {
    percentile25: Team360AssessmentResult;
    percentile50: Team360AssessmentResult;
    percentile75: Team360AssessmentResult;
  } | null;
}

export interface AnalyticsTextTeamScore {
  teamId: number;
  teamName: string;
  teamLeaders: {
    [userAccountId: number]: {
      userAccountId: number;
      firstName: string;
      lastName: string;
    };
  };
  scores: Team360AssessmentResult;
  teamLeaderScores: Team360AssessmentResult;
  teamMemberScores: Team360AssessmentResult;
  scoreLabel: string | null;
  teamMemberCount: number;
}

export interface OverviewAnalyticsText {
  mostRecentResultsDate: string;
  overallScore: number;
  overallScoreLabel: string;
  overviewText: string;
  highestScoringFactor: string;
  lowestScoringFactor: string;
}

interface TextInsightResponses {
  theme: string;
  response: string;
  responseInfo: { dateResponseGiven: string; department: string | null };
}

export interface TextInsightResponse {
  doingWell: TextInsightResponses[];
  couldImproveOn: TextInsightResponses[];
  isSample: boolean;
  responsesCount: number;
}

export interface TextSynthesisResponse {
  themeText: string;
  skillsText: string;
  isSampleResponse: boolean;
}

export type WordCloudResponse = {
  text: string;
  value: number;
}[];

export type TeamAndDepartmentLeadsResponse = {
  users: {
    [userAccountId: number]: {
      teams: number[];
      departments?: number[];
    };
  };
};

export type TeamReflectionSummaryAnswerDetail = {
  count: number;
  color: string;
  percentage: number;
  label: string;
};

export type TeamReflectionSummaryQuestionDetail = {
  answerDetails: {
    [key: string]: TeamReflectionSummaryAnswerDetail;
  };
  labelToDisplay: {
    label: string;
    color: string;
  };
  teamReflectionInsightText?: string;
};

export type TeamReflectionSummary = {
  teamEffectivenessRating: TeamReflectionSummaryQuestionDetail;
  likelihoodToLeaveThisTeam: TeamReflectionSummaryQuestionDetail;
  primaryWorkStyle: TeamReflectionSummaryQuestionDetail;
};

export type TeamComments = {
  doingWellNeedsImprovement: {
    doingWell: string[];
    needsImprovement: string[];
  };
  byWorkStyle: {
    remote: string[];
    hybrid: string[];
    inPerson: string[];
  };
  byGender: {
    male: string[];
    female: string[];
    nonBinary: string[];
  };
  byTeam360Score: {
    low: string[];
    medium: string[];
    high: string[];
  };
};

export type AssessmentInformation = {
  organization: {
    teamsInOrg: number;
    teamsWithActiveAssessments: number;
    activeAssessment: boolean;
  };
  teams?: Record<
    string,
    {
      activeAssessment: boolean;
    }
  >;
  departments?: Record<
    string,
    {
      activeAssessment: boolean;
      teamsInDepartment: number;
      teamsWithActiveAssessments: number;
    }
  >;
};

export type CheckSingleEmailResponse = {
  isValid: boolean;
  error?: string | null;
};

export type TestTakerMatch = {
  testTakerId: number;
  testTakerEmail: string;
  testTakerFirstName: string;
  testTakerLastName: string;
  dateEventTaken: string;
  companyAccountId: number;
  position: string;
  stageName: string;
};

export interface VisibilitySettings {
  "Privacy-Focused": AllPermissionChartProps;
  Balanced: AllPermissionChartProps;
  "Transparency-Focused": AllPermissionChartProps;
}

export interface AllPermissionChartProps {
  IndividualReportPermissionData: PermissionChartProps;
  TeamReportPermissionData: PermissionChartProps;
  DepartmentReportData: PermissionChartProps;
  OrganizationReportData: PermissionChartProps;
  TeamPersonalityReportPermissionData: PermissionChartProps;
}

interface PermissionChartProps {
  template: PermissionChartTemplate;
  columns: {
    label: string;
    key: string;
  }[];
}

export interface PermissionDefaultUpdatedEntities {
  differingTeamIds: number[];
  differingUserAccountIds: number[];
}

export interface PendingTeamMember {
  teamId: number;
  userAccountId: number;
  teamMemberId: number;
}

export type TeamTab = {
  label: string;
  teamIds: number[];
  isDisabled: boolean;
};

export interface WeeklyCheckInTableRowDataBase {
  moodScore: number | null;
  progressScore: number | null;
  readinessScore: number | null;
}

export interface UserWeeklyCheckInTableRowData
  extends WeeklyCheckInTableRowDataBase {
  threadId: string;
  completedDate: string;
  userAccountId: number;
}

export interface GroupWeeklyCheckInTableRowData
  extends WeeklyCheckInTableRowDataBase {
  startDate: string;
  endDate: string;
  completedUserAccountIds: number[];
  invitedUserAccountIds: number[];
  entityId: number;
}

export type WeeklyCheckInTableRowData =
  | UserWeeklyCheckInTableRowData
  | GroupWeeklyCheckInTableRowData;

export const isWeeklyCheckInRowUser = (
  row: WeeklyCheckInTableRowData
): row is UserWeeklyCheckInTableRowData => {
  return (row as UserWeeklyCheckInTableRowData).threadId !== undefined;
};

export interface CheckInResponseSummaryResponse {
  responseSummary: string;
}
export interface WeeklyCheckInLineChartResponse {
  labels: string[];
  averageEnergy: (number | null)[];
  averageProgress: (number | null)[];
  averageReadiness: (number | null)[];
  counts: number[];
  hasPreviousTimePeriodData?: boolean;
  hasNextTimePeriodData?: boolean;
}

export interface WeeklyCheckInLineChartState {
  [timeInterval: string]: {
    [index: number]: WeeklyCheckInLineChartResponse | null;
  };
}

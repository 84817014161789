export const dashboardFilters = [
  {
    filterName: "Most Recent",
  },
  {
    filterName: "Team",
  },
  {
    filterName: "Type",
  },
];

export const VALID_DASHBOARD_ROLES = [
  "Admin",
  "Department Leader",
  "Team Leader / Team Member",
  "Team Member",
] as const;

export const TrackedQuickActionEventsMapByRole = {
  Admin: "adminQuickActionClosed",
  "Department Leader": "departmentLeaderQuickActionClosed",
  "Team Leader / Team Member": "teamLeaderQuickActionClosed",
} as const;

export const CHECK_IN_TIME_RANGE_OPTIONS: Record<string, string> = {
  "Past 7 days": "pastWeek",
  "Past 30 days": "pastMonth",
  "Past 365 days": "pastYear",
};

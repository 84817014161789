import { useState, useEffect, useRef, useCallback } from "react";
import Button from "app/storybookComponents/Button";
import { Card, Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectChatbotMessages,
  selectThreadId,
  selectSendMessageStatus,
  resetChatbotState,
  selectSelectedChatbotPrompt,
  setCoachBotPrompt,
  sendGeneralMessage,
  selectGenerateInstructionsStatus,
  setChatbotMessages,
  regenerateLastMessage,
  selectSubmittingCoachbotFeedbackStatus,
  submitCoachbotFeedback,
  setIsCoachbotOpen,
  selectChatbotFullScreen,
  setChatbotFullScreen,
} from "./slice";
import Input from "./Input";
import Prompt from "./Prompt";
import MessageBubble from "./MessageBubble";
import Disclaimer from "./Disclaimer";
import Robot from "resources/images/robot.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CHATBOT_FEEDBACK_EXAMPLES,
  CHATBOT_OTHER_FEEDBACK,
  CoachbotDefaultPrompts,
} from "./constants";
import { CoachbotCardTemplate } from "../CoachBotPrompts/types";
import {
  ONE_WEEK_SMART_GOAL_TEMPLATE,
  REFLECT_OR_DEBRIEF_TEMPLATE,
} from "../CoachBotPrompts/constants";
import Loading from "app/storybookComponents/Loading";
import TextArea from "app/storybookComponents/Forms/TextArea";

interface Props {
  readonly isOpen: boolean;
  readonly closeChatbot: () => void;
}

export default function Chatbot({ isOpen, closeChatbot }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // ---------------------------------------- Selectors ----------------------------------------
  const messages = useAppSelector(selectChatbotMessages);
  const threadId = useAppSelector(selectThreadId);
  const sendMessageStatus = useAppSelector(selectSendMessageStatus);
  const selectedCoachbotPrompt = useAppSelector(selectSelectedChatbotPrompt);
  const generateInstructionsStatus = useAppSelector(
    selectGenerateInstructionsStatus
  );
  const submittingFeedbackStatus = useAppSelector(
    selectSubmittingCoachbotFeedbackStatus
  );
  const isFullScreen = useAppSelector(selectChatbotFullScreen);

  // ---------------------------------------- State ----------------------------------------
  const [message, setMessage] = useState("");
  const [isHelpful, setIsHelpful] = useState<null | boolean>(null);
  const [showFeedbackInput, setShowFeedbackInput] = useState(false);
  const [feedbackInputText, setFeedbackInputText] = useState("");
  const [showThanksForFeedbackMessage, setShowThanksForFeedbackMessage] =
    useState(false);

  // ---------------------------------------- Refs ----------------------------------------
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (sendMessageStatus === "succeeded") {
      inputRef.current?.focus();
    }

    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [sendMessageStatus]);

  const getLoadingMessage = useCallback((): string => {
    const options = [
      "Please wait, Coach Bo is on the way...",
      "Please wait, Coach Bo is reviewing your situation...",
      "Please wait, Coach Bo is running down the hall...",
      "Please wait, Coach Bo is finding some files...",
      "Please wait, Coach Bo is just finishing a call...",
    ];
    const randomIndex = Math.floor(Math.random() * options.length);
    return options[randomIndex];
  }, []);

  if (!isOpen) return null;

  const setIsFullScreen = (value: boolean) => {
    dispatch(setChatbotFullScreen(value));
  };

  const closeButtonWrapper = () => {
    closeChatbot();
    setIsFullScreen(false);
  };

  const reversedMessages = messages ? [...messages].reverse() : [];

  const showSuggestedPrompts = !message && messages.length <= 1;

  const handleSendMessage = (messageToSend: string) => {
    if (!messageToSend) return;
    dispatch(
      sendGeneralMessage({
        message: messageToSend,
      })
    );
    setMessage("");
    setIsHelpful(null);
  };

  const getSuggestedPromptsArray = (): string[] => {
    if (selectedCoachbotPrompt?.userPrompts) {
      return selectedCoachbotPrompt?.userPrompts;
    }
    const promptMapCopy: {
      [key: string]: string;
    } = { ...CoachbotDefaultPrompts };

    if (pathname === "/CoachBoPrompts") {
      delete promptMapCopy.SeeAllPromptTitle;
    }
    return Object.values(promptMapCopy);
  };

  const onTemplateSelect = async (
    template: CoachbotCardTemplate,
    message: string
  ) => {
    dispatch(resetChatbotState());
    dispatch(setIsCoachbotOpen(true));
    await dispatch(
      setCoachBotPrompt({
        userPrompts: template.preSelectedPrompts ?? [],
        coachBotIntro: "",
        chatType: template.chatType ?? "",
      })
    );
    handleSendMessage(message);
  };

  const handlePromptSelect = (prompt: string) => {
    switch (prompt) {
      case CoachbotDefaultPrompts.SeeAllPromptTitle:
        navigate("/CoachBoPrompts");
        closeButtonWrapper();
        break;
      case CoachbotDefaultPrompts.CreateOneWeekGoal:
        onTemplateSelect(ONE_WEEK_SMART_GOAL_TEMPLATE, prompt);
        break;
      case CoachbotDefaultPrompts.ReflectOnOverall:
        onTemplateSelect(REFLECT_OR_DEBRIEF_TEMPLATE, prompt);
        break;
      default:
        handleSendMessage(prompt);
    }
  };

  const getSuggestedPrompts = () => {
    if (generateInstructionsStatus === "loading") {
      return null;
    }
    const suggestedPrompts = [...getSuggestedPromptsArray()];

    return (
      <div className="chatbot-prompt-container">
        {suggestedPrompts.map((prompt) => (
          <Prompt
            key={prompt}
            prompt={prompt}
            handleSendMessage={handlePromptSelect}
          />
        ))}
      </div>
    );
  };

  const getButton = (
    fontIcon: "arrows-rotate" | "thumbs-up" | "thumbs-down",
    popoverText: string,
    onClick: () => void
  ) => {
    const overlay = (
      <Popover className="team-360-popover">
        <div>{popoverText}</div>
      </Popover>
    );

    return (
      <OverlayTrigger trigger={["hover", "focus"]} overlay={overlay}>
        <div>
          <Button
            variant="secondary-blue"
            onClick={onClick}
            style={{
              padding: "6px 8px",
              border: 0,
            }}
          >
            <FontAwesomeIcon icon={fontIcon} />
          </Button>
        </div>
      </OverlayTrigger>
    );
  };

  const onSubmitFeedback = async (feedback: string) => {
    // send request to the api
    if (isHelpful === null) {
      return;
    }
    await dispatch(
      submitCoachbotFeedback({
        feedback,
        feedbackType: isHelpful ? "thumbsUp" : "thumbsDown",
      })
    );
    setShowThanksForFeedbackMessage(true);
    setTimeout(() => {
      setShowThanksForFeedbackMessage(false);
      setIsHelpful(null);
    }, 4000);
  };

  const getTellUsMore = () => {
    if (isHelpful === null) {
      return null;
    }

    if (showThanksForFeedbackMessage) {
      return (
        <div
          className={`fade-out-container ${
            showThanksForFeedbackMessage ? "" : "hide"
          }`}
        >
          Thanks for the feedback!
        </div>
      );
    }

    const xButton = (
      <Button
        xIcon
        style={{
          position: "absolute",
          right: "10px",
          top: "10px",
          padding: "1px 4px",
          border: 0,
        }}
        variant="secondary-blue"
        onClick={() => {
          setIsHelpful(null);
          setShowFeedbackInput(false);
        }}
      />
    );
    if (showFeedbackInput) {
      return (
        <Card className="p-2 position-relative">
          {xButton}
          <TextArea
            inputLabel="Let us know your comments:"
            inputText={feedbackInputText}
            onTextChange={(e) => setFeedbackInputText(e)}
            placeholder="Feel free to add any specific details here..."
            controlId="feedback-input"
          />
          <Button
            onClick={() => {
              onSubmitFeedback(feedbackInputText);
            }}
            disabled={!feedbackInputText}
            style={{
              marginTop: "12px",
            }}
          >
            Submit
          </Button>
        </Card>
      );
    }

    const helpfulOrNot = isHelpful ? "helpful" : "notHelpful";
    return (
      <Card className="p-2 position-relative">
        {xButton}
        <p className="textarea-label mb-2">Tell us more:</p>
        <div className="row-gap-8px flex-wrap">
          {CHATBOT_FEEDBACK_EXAMPLES[helpfulOrNot].map((tip) => (
            <Button
              onClick={() => {
                if (tip === CHATBOT_OTHER_FEEDBACK) {
                  setShowFeedbackInput(true);
                } else {
                  onSubmitFeedback(tip);
                }
              }}
              variant="secondary-blue"
              disabled={submittingFeedbackStatus === "loading"}
              key={tip}
            >
              {tip}
            </Button>
          ))}
        </div>
      </Card>
    );
  };

  const getFeedbackContainer = () => {
    if (
      reversedMessages.length <= 1 ||
      reversedMessages[0]?.role !== "assistant"
    ) {
      return null;
    }

    return (
      <div className="column-gap-6px">
        <div className="row-gap-6px">
          {getButton("arrows-rotate", "Regenerate", () => {
            dispatch(regenerateLastMessage());
            setIsHelpful(null);
          })}
          {getButton("thumbs-up", "Good Response", () => {
            setIsHelpful(true);
          })}
          {getButton("thumbs-down", "Bad Response", () => {
            setIsHelpful(false);
          })}
        </div>
        {getTellUsMore()}
      </div>
    );
  };

  const onDropdownSelect = (e: string | null) => {
    if (e !== "reset") {
      return;
    }

    const firstMessage = messages[0];
    dispatch(
      resetChatbotState({
        keepPrompt: true,
        keepChatOpen: true,
      })
    );
    dispatch(setChatbotMessages([firstMessage]));
  };

  const getMessageContainer = () => {
    if (generateInstructionsStatus === "loading") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Loading color="black" />
          <div style={{ fontWeight: "bold" }}>{getLoadingMessage()}</div>
          <div>This will take less than a minute.</div>
        </div>
      );
    }

    return (
      <div className="inner-chatbot-message-container">
        {getErrorBanner()}
        {sendMessageStatus === "loading" && (
          <div className="assistant-bubble-row">
            <img src={Robot} alt="Criteria" className="assistant-avatar" />
            <div className="assistant-bubble">
              <div className="typing-dots">
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>
            </div>
          </div>
        )}
        <div
          ref={messagesEndRef}
          style={{
            height: "0px",
          }}
        />
        {getFeedbackContainer()}
        {reversedMessages.map((message, index) => (
          <MessageBubble
            key={index}
            type={message.role}
            message={message.content}
          />
        ))}
      </div>
    );
  };

  const getErrorBanner = () => {
    if (sendMessageStatus === "failed" && !threadId) {
      return (
        <div
          className={`warning-banner red d-flex align-items-center justify-content-between`}
          style={{
            padding: "12px 16px",
            marginBottom: "10px",
          }}
        >
          <FontAwesomeIcon icon="exclamation-triangle" />
          <p>Coach Bo is currently unavailable. Please try again later.</p>
        </div>
      );
    }
    return null;
  };

  const getDropdown = () => {
    if (selectedCoachbotPrompt?.chatType === "savedChat") {
      return null;
    }
    return (
      <Dropdown onSelect={onDropdownSelect} style={{ paddingRight: "10px" }}>
        <Dropdown.Toggle
          variant="outline-primary"
          id="dropdown-basic"
          className="no-caret"
        >
          <FontAwesomeIcon icon="ellipsis" size="sm" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="flip">
          <Dropdown.Item
            eventKey="reset"
            disabled={!threadId || messages.length <= 1}
          >
            Reset Chat History
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <>
      {isFullScreen && (
        <div
          className="backdrop"
          onClick={() => setIsFullScreen(false)}
          role="button"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setIsFullScreen(false);
            }
          }}
        />
      )}
      <div className={isFullScreen ? "full-screen-wrapper" : ""}>
        <Card className="chatbot-card">
          <div className="chatbot-header">
            <h2>
              Coach Bo
              <FontAwesomeIcon
                onClick={() => setIsFullScreen(!isFullScreen)}
                icon={
                  isFullScreen
                    ? "down-left-and-up-right-to-center"
                    : "up-right-and-down-left-from-center"
                }
                className="chatbot-fullscreen-toggle"
              />
            </h2>
            <div style={{ display: "flex", alignItems: "center" }}>
              {getDropdown()}

              <Button
                xIcon
                variant="secondary-blue"
                className="border-0"
                onClick={() => {
                  closeButtonWrapper();
                  setIsFullScreen(false);
                }}
              />
            </div>
          </div>
          {/* Render messages */}
          <div
            className="chatbot-message-container"
            style={{
              flexDirection: isFullScreen ? "column" : "column-reverse",
            }}
          >
            {getMessageContainer()}
          </div>

          {showSuggestedPrompts &&
            sendMessageStatus !== "loading" &&
            getSuggestedPrompts()}
          <div
            style={{
              width: "100%",
            }}
          >
            <Input
              message={message}
              isFullScreen={isFullScreen}
              setMessage={setMessage}
              handleSendMessage={handleSendMessage}
              disabled={sendMessageStatus === "loading"}
              ref={inputRef}
            />
            <Disclaimer />
            {isFullScreen && (
              <div className="chatbot-links">
                If you need additional help, you can email your Support Team at{" "}
                <a href="mailto:techsupport@criteriacorp.com">
                  techsupport@criteriacorp.com
                </a>
              </div>
            )}
          </div>
        </Card>
      </div>
    </>
  );
}

import {
  ModuleTemplates,
  Guide,
  UsedModuleTemplateIds,
  isModuleType,
  ModuleType,
  BaseModuleTemplate,
} from "app/components/Modules/types";
import { TeamAnalyticsText } from "app/containers/TeamGuide/types";
import { Team } from "app/containers/Global/types";
import { PendingAssessmentNotification } from "app/components/Notifications/types";
import ModuleCard from "app/components/Modules/Cards/ModuleCard";
import MemberCardWithNoButton from "app/components/MemberCard/MemberCardWithNoButton";
import { TeamMostRecentInstanceInfo } from "../Assessment/types";
import { Dictionary } from "@reduxjs/toolkit";
import { CoachBoWeeklyCheckInActivityReport } from "./types";
import { ActivityMonitorBoxProps } from "app/components/ActivityMonitor/types";

// Will take in teamId, teamGuideModuleTemplates, and Team guide
// We will then return back two module for sections that the team has not answered.
// If the team has answered modules for each section then we can show a module for an already answered section
// Will take in userAccountId, userGuideModuleTemplates, and User guide
// We will then return back two module for sections that the user has not answered.
// If the user has answered modules for each section then we can show a module for an already answered section
export const getModulesToAdd = (payload: {
  moduleTemplates: ModuleTemplates | null;
  guide: Guide;
  usedModules: { [id: number]: UsedModuleTemplateIds };
  onModuleAdd: (moduleTemplateId: number, moduleType: ModuleType) => void;
}) => {
  const { moduleTemplates, usedModules, onModuleAdd } = payload;
  if (!moduleTemplates) return;
  const unusedSections = getUnusedModuleTemplates(moduleTemplates, usedModules);
  return unusedSections.map((moduleTemplate) => (
    <ModuleCard
      key={`${moduleTemplate.moduleTemplateId}_${moduleTemplate.moduleType}`}
      moduleTemplateId={moduleTemplate.moduleTemplateId}
      moduleType={moduleTemplate.moduleType}
      title={moduleTemplate.title}
      shortDescription={moduleTemplate.shortDescription}
      iconName={moduleTemplate.iconName}
      onAddModule={() =>
        onModuleAdd(moduleTemplate.moduleTemplateId, moduleTemplate.moduleType)
      }
    />
  ));
};

export const getUnusedModuleTemplates = (
  moduleTemplates: ModuleTemplates,
  usedModules: Partial<UsedModuleTemplateIds> = {}
): (BaseModuleTemplate & {
  moduleType: ModuleType;
})[] => {
  // First we will make a copy of the Module Templates
  const copyModuleTemplates = JSON.parse(
    JSON.stringify(moduleTemplates)
  ) as ModuleTemplates;

  // Then remove the usedModules from the Module templates
  Object.entries(usedModules).forEach(([key, valueArr]) => {
    if (!isModuleType(key)) return;
    valueArr.forEach((id) => {
      if (copyModuleTemplates[key][id]) {
        // We remove the already added modules from the copy so that we don't show it again to add.
        delete copyModuleTemplates[key][id];
      }
    });
  });

  // When removing the usedModules we will keep track of which section have already been used
  const returnArr: (BaseModuleTemplate & {
    moduleType: ModuleType;
  })[] = [];

  // We will find the max for the module templates
  const maxLength = Object.values(copyModuleTemplates).reduce(
    (acc, curr) => Math.max(acc, Object.keys(curr).length),
    0
  ) as number;

  let idx = 0;
  while (idx < maxLength && returnArr.length < 2) {
    Object.entries(copyModuleTemplates).forEach(
      // eslint-disable-next-line no-loop-func
      ([moduleType, moduleTemplateMap]) => {
        const temp = Object.values(moduleTemplateMap)[
          idx
        ] as BaseModuleTemplate;
        if (!temp) return;
        returnArr.push({ ...temp, moduleType: moduleType as ModuleType });
      }
    );

    idx += 1;
  }

  return returnArr;
};

// Should return only the top 4 members
export const getMembersFromTeam = (team: Team | null) => {
  if (!team) return null;
  const members =
    team.teamMemberIds?.map((userAccountId) => {
      return (
        <MemberCardWithNoButton
          userAccountId={userAccountId}
          key={userAccountId}
        />
      );
    }) ?? [];
  const pendingMembers =
    team.pendingTeamMemberIds?.map((userAccountId) => {
      return (
        <MemberCardWithNoButton
          key={userAccountId}
          userAccountId={userAccountId}
          isPendingForTeam
          customPendingMessage="Pending team invitation"
        />
      );
    }) ?? [];

  const totalMembers = [...members, ...pendingMembers].splice(0, 4);

  return <div className="four-team-member-holder">{totalMembers}</div>;
};

export const getTeamTotalText = (
  teamTotal: number = 0,
  totalPending: number = 0
) => {
  const acceptedTeams = teamTotal - totalPending;
  if (totalPending === 0 && acceptedTeams === 0) {
    return "";
  } else if (acceptedTeams === 0 && totalPending !== 0) {
    return `${totalPending} pending invite${totalPending === 1 ? "" : "s"}`;
  } else if (acceptedTeams !== 0 && totalPending === 0) {
    return `${acceptedTeams} team${acceptedTeams === 1 ? "" : "s"} joined`;
  } else {
    return `${acceptedTeams} team${
      acceptedTeams === 1 ? "" : "s"
    } joined, ${totalPending} pending invite${totalPending === 1 ? "" : "s"}`;
  }
};

export const getTeamMemberTotalText = (
  acceptedMemberCount: number = 0,
  pendingMemberCount: number = 0
): string => {
  if (pendingMemberCount === 0 && acceptedMemberCount === 0) {
    return "";
  } else if (acceptedMemberCount === 0 && pendingMemberCount !== 0) {
    return `${pendingMemberCount} member${
      pendingMemberCount === 1 ? "" : "s"
    } invited`;
  } else if (acceptedMemberCount !== 0 && pendingMemberCount === 0) {
    return `${acceptedMemberCount} total member${
      acceptedMemberCount === 1 ? "" : "s"
    }`;
  } else {
    return `${acceptedMemberCount} total member${
      acceptedMemberCount === 1 ? "" : "s"
    }, ${pendingMemberCount} pending invite${
      pendingMemberCount === 1 ? "" : "s"
    }`;
  }
};

// Takes in notifications as param and return back an object with the teamId being the key and the value being the pending assessment notification
export const getNotificationTeamMap = (
  pendingAssessmentNotifications?: PendingAssessmentNotification[]
): {
  [teamId: number]: PendingAssessmentNotification;
} => {
  if (!pendingAssessmentNotifications) return {};
  const notificationMap: {
    [teamId: number]: PendingAssessmentNotification;
  } = {};
  pendingAssessmentNotifications?.forEach((notification) => {
    if (!notification.teamId) return;
    if (!notificationMap[notification.teamId]) {
      notificationMap[notification.teamId] = notification;
    }
  });
  return notificationMap;
};

export const getHasAnyTeam360Results = (
  allMostRecentAssessments: Dictionary<TeamMostRecentInstanceInfo>,
  teamIds?: number[]
) =>
  !!teamIds?.some((teamId) => allMostRecentAssessments[teamId]?.totalInvited);

export const getHowManyTeamsHaveTeam360Results = (
  allTeamAnalyticText: { [teamId: number]: TeamAnalyticsText },
  teamIds?: number[]
): number =>
  teamIds?.reduce(
    (acc, teamId) =>
      allTeamAnalyticText[teamId]?.completionInfo?.totalInvited ? acc + 1 : acc,
    0
  ) ?? 0;

export const getLabelColor = (label: string) => {
  if (label === "above") {
    return "green";
  } else if (label === "below") {
    return "red";
  }
  return "yellow";
};

export const getArrowValue = (label: string): "up" | "down" | null => {
  if (label === "above") {
    return "up";
  } else if (label === "below") {
    return "down";
  }
  return null;
};

export const getWeekFromEndDateOrFromNow = (endDate?: string) => {
  if (!endDate) return "";
  const endDatePlus7Days = new Date(endDate);
  endDatePlus7Days.setDate(endDatePlus7Days.getDate() + 7);
  const now = new Date();
  if (endDatePlus7Days < now) {
    now.setDate(now.getDate() + 7);
    return now.toISOString();
  }
  return endDatePlus7Days.toISOString();
};

export const getCoachBoWeeklyCheckInActivityReportToActivityMonitorBoxProps = (
  coachBoWeeklyCheckInActivityReport?: CoachBoWeeklyCheckInActivityReport,
  timeInterval: string = "week"
): ActivityMonitorBoxProps[] => {
  const activityMonitorBoxProps: ActivityMonitorBoxProps[] = [];
  if (!coachBoWeeklyCheckInActivityReport) return activityMonitorBoxProps;

  const { completionInfo, energy, progress, readiness } =
    coachBoWeeklyCheckInActivityReport;

  const hasSomeData =
    !!completionInfo.completedCount || !!completionInfo.totalCount;

  activityMonitorBoxProps.push({
    title: "Check-in Completion",
    mainValue: `${completionInfo.percentage}%`,
    changeSinceLast: getChangeSinceLast(
      completionInfo.trendAmount,
      completionInfo.trendDirection,
      true
    ),
    descriptionText: hasSomeData
      ? `${completionInfo.completedCount ?? 0} / ${completionInfo.totalCount}`
      : null,
    timeInterval,
    dotColor: getOutOfFiveColor(completionInfo.percentage / 20),
    popoverText:
      "This is the percentage of Weekly Check-Ins completed out of all those sent in this organization.",
  });

  const averageScoreDescriptionText = hasSomeData ? "out of 5" : null;

  activityMonitorBoxProps.push({
    title: "Average Mood Score",
    mainValue: energy.score,
    changeSinceLast: getChangeSinceLast(
      energy.trendAmount,
      energy.trendDirection
    ),
    descriptionText: averageScoreDescriptionText,
    timeInterval,
    dotColor: energy.score ? getOutOfFiveColor(energy.score) : null,
    popoverText:
      "In the Weekly Check-In, Coach Bo asks members how they’re feeling. Their answers are scored by AI on a scale of 1 (lowest) to 5 (highest). This is the average of all mood scores from the selected time frame.",
  });

  activityMonitorBoxProps.push({
    title: "Average Progress Score",
    mainValue: progress.score,
    changeSinceLast: getChangeSinceLast(
      progress.trendAmount,
      progress.trendDirection
    ),
    descriptionText: averageScoreDescriptionText,
    timeInterval,
    dotColor: progress.score ? getOutOfFiveColor(progress.score) : null,
    popoverText:
      "In the Weekly Check-In, Coach Bo asks members what they’ve been working on and their progress each week. Their answers are scored by AI on a scale of 1 (no progress) to 5 (great progress). This is the average of all progress scores from the selected time frame.",
  });

  activityMonitorBoxProps.push({
    title: "Average Readiness Score",
    mainValue: readiness.score,
    changeSinceLast: getChangeSinceLast(
      readiness.trendAmount,
      readiness.trendDirection
    ),
    descriptionText: averageScoreDescriptionText,
    timeInterval,
    dotColor: readiness.score ? getOutOfFiveColor(readiness.score) : null,
    popoverText:
      "In the Weekly Check-In, Coach Bo asks members if anything is preventing them from making progress in their work. Their answers are scored by AI on a scale of 1 (major blockers) to 5 (no blockers). This is the average of all readiness scores from the selected time frame.",
  });

  return activityMonitorBoxProps;
};

const getChangeSinceLast = (
  trendAmount: number | null,
  trendDirection: string | null,
  addPercentage?: boolean
) => {
  if (trendAmount === null) {
    return null;
  }
  return {
    isPositive: trendDirection === "up",
    totalChange: addPercentage ? `${trendAmount}%` : trendAmount,
  };
};

export const getOutOfFiveColor = (score: number = 0) => {
  if (score > 4.5) {
    return "blue";
  }
  if (score > 3) {
    return "green";
  }
  if (score > 2) {
    return "yellow";
  }
  if (score > 1) {
    return "poppy";
  }
  return "red";
};

export const getWeeklyCheckInActivityTimeIntervalKey = (
  selectedKey?: string
) => {
  switch (selectedKey) {
    case "Past 30 days":
      return "last30Days";
    case "Past 365 days":
      return "last365Days";
    default:
      return null;
  }
};

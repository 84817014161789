import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectAllCompanyUsersById,
  selectCurrentUserAccountId,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import ManageTeamScanModal from "app/components/Modals/ManageTeamScanModal";
import {
  getPendingTests,
  getSuperpowersAndHiddenStrengths,
  getWorkPlaceInsightsReportByUserId,
  selectGetPendingTestsStatus,
} from "app/components/WorkplaceInsightsReport/slice";
import TodoCard from "app/components/Notifications/TodoCard";
import ShortcutsCard from "../Cards/ShortcutsCard";
import { getManagerAndDirectReports } from "app/containers/AdvancedCsvUpload/slice";

export default function MemberDashboardV2() {
  const dispatch = useAppDispatch();

  // ------------------------------- Selectors --------------------------------------------- //
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const loggedInUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const getPendingTestsStatus = useAppSelector(selectGetPendingTestsStatus);

  // ------------------------------------- Component States ------------------------------------- //
  const [isManageTeamScanModalOpen, setIsManageTeamScanModalOpen] =
    useState(false);
  const [manageTeamScanModalTeams, setManageTeamScanModalTeams] = useState<
    number[] | null
  >(null);
  // ------------------------------------- Effect ------------------------------------- //

  useEffect(() => {
    if (!loggedInUserAccountId) return;
    dispatch(getWorkPlaceInsightsReportByUserId(loggedInUserAccountId));
    dispatch(getSuperpowersAndHiddenStrengths(loggedInUserAccountId));
    dispatch(
      getManagerAndDirectReports({ userAccountId: loggedInUserAccountId })
    );
  }, [loggedInUserAccountId, dispatch]);

  useEffect(() => {
    if (getPendingTestsStatus === "idle") {
      dispatch(getPendingTests());
    }
  }, [getPendingTestsStatus, dispatch]);

  // ------------------------------------- Memos ------------------------------------- //
  const userInfo = useMemo(() => {
    return loggedInUserAccountId ? usersById[loggedInUserAccountId] : null;
  }, [loggedInUserAccountId, usersById]);

  const userTeams = useMemo(() => {
    if (!userInfo?.teamIds) return [];
    // Filter out any teamIds that do not exist in the teamsById object
    return userInfo.teamIds.filter((teamId) => teamsById[teamId]);
  }, [teamsById, userInfo]);

  return (
    <div className="dashboard-container">
      <ManageTeamScanModal
        show={isManageTeamScanModalOpen}
        onHide={() => {
          setIsManageTeamScanModalOpen(false);
          setManageTeamScanModalTeams(null);
        }}
        teams={manageTeamScanModalTeams || userTeams}
      />
      <TodoCard />
      <ShortcutsCard />
    </div>
  );
}

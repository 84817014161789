import { useEffect, useMemo, useState } from "react";
import Button from "app/storybookComponents/Button";
import NoResultsIcon from "resources/images/no-results-transparent-back.png";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { selectConfigCatFlag } from "app/containers/Global/slice";
import Loading from "app/storybookComponents/Loading";
import ActivityMonitorContainerEmptyContent from "app/components/ActivityMonitor/ActivityMonitorContainerEmptyContent";
import ActivityMonitorContainer from "app/components/ActivityMonitor/ActivityMonitorContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CHECK_IN_TIME_RANGE_OPTIONS } from "../constants";
import {
  getCoachBoWeeklyCheckInActivityReport,
  getWeeklyCheckInModuleStatus,
  selectCoachBoWeeklyCheckInActivityReports,
  selectGetCoachBoWeeklyCheckInActivityReportStatus,
  selectGetWeeklyCheckInModuleStatus,
  selectShowWeeklyCheckInActivityEmptyState,
} from "../slice";
import {
  getCoachBoWeeklyCheckInActivityReportToActivityMonitorBoxProps,
  getWeeklyCheckInActivityTimeIntervalKey,
} from "../helpers";
import { useNavigate } from "react-router-dom";
import SimpleLineChart from "app/storybookComponents/Charts/SimpleLineChart";
import {
  getWeeklyCheckInLineChartData,
  selectWeeklyCheckInLineChartData,
  selectGetWeeklyCheckInLineChartDataStatus,
} from "app/containers/AdminConsole/slice";
import ChartLegend from "app/storybookComponents/Charts/ChartLegend";
import { TooltipItem } from "chart.js";
import SimpleCollapsibleCard from "app/storybookComponents/CollapsibleCards/SimpleCollapsibleCard";
import { trackFeatureViewed } from "utils/trackingFunctions";

const WeeklyCheckInActivityMonitor = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // ------------------------------- Selectors --------------------------------------------- //
  const coachBoWeeklyCheckInActivityReports = useAppSelector(
    selectCoachBoWeeklyCheckInActivityReports
  );
  const getCoachBoWeeklyCheckInActivityReportStatus = useAppSelector(
    selectGetCoachBoWeeklyCheckInActivityReportStatus
  );
  const getShowWeeklyCheckInActivityEmptyStateStatus = useAppSelector(
    selectGetWeeklyCheckInModuleStatus
  );
  const showWeeklyCheckInActivityEmptyState = useAppSelector(
    selectShowWeeklyCheckInActivityEmptyState
  );
  const weeklyCheckInConfigCatFlag = useAppSelector(
    selectConfigCatFlag("weeklyCheckIn")
  );
  const weeklyCheckInLineChartData = useAppSelector(
    selectWeeklyCheckInLineChartData
  );
  const fetchingWeeklyCheckInLineChartDataStatus = useAppSelector(
    selectGetWeeklyCheckInLineChartDataStatus
  );

  // ------------------------------------- Component States ------------------------------------- //
  const [
    weeklyCheckInActivityTimeInterval,
    setWeeklyCheckInActivityTimeInterval,
  ] = useState("Past 7 days");
  const [lineChartNavigationIndex, setLineChartNavigationIndex] = useState(1);
  const [isLineChartCollapsed, setIsLineChartCollapsed] = useState(true);

  // ------------------------------------- Memo States ------------------------------------- //
  const activeCoachBoWeeklyCheckInActivityReport = useMemo(() => {
    const activeKey =
      CHECK_IN_TIME_RANGE_OPTIONS[weeklyCheckInActivityTimeInterval];

    return coachBoWeeklyCheckInActivityReports[activeKey];
  }, [weeklyCheckInActivityTimeInterval, coachBoWeeklyCheckInActivityReports]);

  const activeCoachBoCheckInLineChartData = useMemo(() => {
    const dataKey = getWeeklyCheckInActivityTimeIntervalKey(
      weeklyCheckInActivityTimeInterval
    );
    return (
      dataKey && weeklyCheckInLineChartData[dataKey]?.[lineChartNavigationIndex]
    );
  }, [
    weeklyCheckInLineChartData,
    weeklyCheckInActivityTimeInterval,
    lineChartNavigationIndex,
  ]);

  // ------------------------------------- Effect ------------------------------------- //
  useEffect(() => {
    trackFeatureViewed("Weekly Check-In Activity Dashboard Card");
  }, []);

  useEffect(() => {
    dispatch(getCoachBoWeeklyCheckInActivityReport());
    dispatch(getWeeklyCheckInModuleStatus());
  }, [dispatch]);

  // ------------------------------------- Render ------------------------------------- //

  if (!weeklyCheckInConfigCatFlag) {
    return null;
  }

  const getFooter = () => {
    const { completionInfo } = activeCoachBoWeeklyCheckInActivityReport ?? {};
    if (!completionInfo?.completedCount && !completionInfo?.totalCount) {
      return (
        <div className="warning-banner blue">
          <p>
            No Check-Ins received yet. Please check back in <b>5 days.</b> Once
            responses are received, score averages will be available here.
          </p>
        </div>
      );
    }

    return (
      <div className="warning-banner blue">
        <Button
          variant="secondary-blue"
          onClick={() => {
            navigate("/AdminConsole/Check-In Activity");
          }}
          className="border-0"
        >
          View activity <FontAwesomeIcon icon={["fas", "arrow-right"]} />
        </Button>
      </div>
    );
  };
  const getToolTipBody = (tooltipItems: TooltipItem<"line">[]) => {
    const index = tooltipItems[0].dataIndex;
    const countArray = activeCoachBoCheckInLineChartData?.counts ?? [];
    const count = countArray[index] ?? 0;
    return `Number of responses: ${count}`;
  };

  const getLineChartEmptyState = (notEnoughDataPoints?: boolean) => {
    const title = `Chart Unavailable for ‘${weeklyCheckInActivityTimeInterval}’`;
    let description =
      "Once there is comparison data, this chart will be shown here.";

    if (weeklyCheckInActivityTimeInterval === "Past 7 days") {
      description =
        "This chart requires more than 7 days of data to present meaningful trends. Please select 'Past 30 days' or 'Past 365 days' to visualize the chart.";
    } else if (notEnoughDataPoints) {
      description =
        "There's not enough data in this time period to build a trend chart. Trend charts require data in at least 2 time periods. Try another time period.";
    }

    return (
      <div className="line-chart-empty-state">
        <img
          src={NoResultsIcon}
          alt="no results"
          className="line-chart-empty-state__illustration"
        />
        <div>
          <div className="line-chart-empty-state__title">
            <p>{title}</p>
          </div>
          <div className="line-chart-empty-state__description">
            <p>{description}</p>
          </div>
        </div>
      </div>
    );
  };

  const onLineChartNavigate = (newIndex: number) => {
    const index = Math.max(1, newIndex);
    setLineChartNavigationIndex(index);
    const dataKey = getWeeklyCheckInActivityTimeIntervalKey(
      weeklyCheckInActivityTimeInterval
    );
    if (dataKey) {
      dispatch(getWeeklyCheckInLineChartData({ timeInterval: dataKey, index }));
    }
  };

  const getLineChart = () => {
    if (fetchingWeeklyCheckInLineChartDataStatus === "loading") {
      return <Loading />;
    }
    const lineChartData = getLineChartData();
    if (!lineChartData || lineChartData.hasAtLeastTwoDataPoints === false) {
      return getLineChartEmptyState(
        lineChartData?.hasAtLeastTwoDataPoints === false
      );
    }
    const { dataValues, labels } = lineChartData;
    const { hasNextTimePeriodData, hasPreviousTimePeriodData } =
      activeCoachBoCheckInLineChartData ?? {};
    return (
      <div className="column-gap-20px">
        <SimpleLineChart
          dataValues={dataValues}
          labels={labels}
          canvasId="weekly-check-in-activity-line-chart"
          maxYScale={5.1}
          stepSize={1}
          tooltipCallbacks={{
            title: () => "",
            label: () => "",
            afterBody: getToolTipBody,
          }}
        />
        <div className="d-flex justify-content-between">
          <Button
            variant="secondary-blue"
            disabled={!hasPreviousTimePeriodData}
            onClick={() => {
              onLineChartNavigate(lineChartNavigationIndex + 1);
            }}
          >
            <FontAwesomeIcon icon="arrow-left" className="me-2" />
            Previous
          </Button>
          <ChartLegend
            labels={["Average Mood", "Average Progress", "Average Blockers"]}
          />
          <Button
            variant="secondary-blue"
            disabled={!hasNextTimePeriodData}
            onClick={() => {
              onLineChartNavigate(lineChartNavigationIndex - 1);
            }}
          >
            Next
            <FontAwesomeIcon icon="arrow-right" className="ms-2" />
          </Button>
        </div>
      </div>
    );
  };

  const getLineChartData = (): {
    dataValues: (number | null)[][];
    labels: string[];
    hasAtLeastTwoDataPoints: boolean;
  } | null => {
    if (!activeCoachBoCheckInLineChartData) {
      return null;
    }
    const {
      labels,
      averageEnergy = [],
      averageProgress = [],
      averageReadiness = [],
    } = activeCoachBoCheckInLineChartData;
    const dataValues: (number | null)[][] = [];
    dataValues.push(averageEnergy);
    dataValues.push(averageProgress);
    dataValues.push(averageReadiness);

    const hasAtLeastTwoDataPoints =
      averageEnergy.filter((value) => value !== null).length > 1;

    return {
      dataValues,
      labels,
      hasAtLeastTwoDataPoints,
    };
  };

  const getEmptyState = (): {
    isEmpty: boolean;
    emptyStateContent: React.ReactElement;
  } => {
    if (
      getCoachBoWeeklyCheckInActivityReportStatus === "loading" ||
      getShowWeeklyCheckInActivityEmptyStateStatus === "loading"
    ) {
      return {
        isEmpty: true,
        emptyStateContent: <Loading />,
      };
    }

    return {
      isEmpty: showWeeklyCheckInActivityEmptyState,
      emptyStateContent: (
        <ActivityMonitorContainerEmptyContent
          title="Start Weekly Check-Ins"
          button={
            <Button variant="secondary-blue" className="white-button">
              See how it works
            </Button>
          }
          description="AI-powered weekly check-ins enable managers to get summarized Monday reports on team mood, status, and blockers while tracking trends over time and highlighting key conversations to have."
        />
      ),
    };
  };

  const weeklyCheckInTimeInterval = weeklyCheckInActivityTimeInterval
    .split("Past")[1]
    .trim()
    .toLowerCase();

  const { isEmpty, emptyStateContent } = getEmptyState();

  return (
    <ActivityMonitorContainer
      title="Weekly Check-In Activity"
      labelTags={Object.keys(CHECK_IN_TIME_RANGE_OPTIONS)}
      activeTag={weeklyCheckInActivityTimeInterval}
      onTagClick={(tag) => {
        setWeeklyCheckInActivityTimeInterval(tag);
        setLineChartNavigationIndex(1);
        const dataKey = getWeeklyCheckInActivityTimeIntervalKey(tag);
        if (dataKey) {
          dispatch(getWeeklyCheckInLineChartData({ timeInterval: dataKey }));
        }
      }}
      boxProps={getCoachBoWeeklyCheckInActivityReportToActivityMonitorBoxProps(
        activeCoachBoWeeklyCheckInActivityReport,
        weeklyCheckInTimeInterval
      )}
      footer={
        <>
          <SimpleCollapsibleCard
            className="column-gap-20px"
            title={
              <span className="grey-text fw-bold">
                Mood, Progress, and Readiness Over Time
              </span>
            }
            isOpen={!isLineChartCollapsed}
            setIsOpen={() => {
              setIsLineChartCollapsed(!isLineChartCollapsed);
              trackFeatureViewed("Weekly Check-In Activity Line Chart", {
                timeInterval: weeklyCheckInActivityTimeInterval,
                location: "dashboard",
              });
            }}
            collapseText="Hide chart"
            expandText="Show chart"
          >
            {getLineChart()}
          </SimpleCollapsibleCard>
          {getFooter()}
        </>
      }
      timeInterval={weeklyCheckInTimeInterval}
      titleTag={
        <div>
          <FontAwesomeIcon icon={["far", "sparkles"]} />
          <span className="label-tag info-sapphire">Powered by AI</span>
        </div>
      }
      isEmpty={isEmpty}
      emptyStateContent={emptyStateContent}
    />
  );
};

export default WeeklyCheckInActivityMonitor;
